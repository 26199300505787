import { Notification } from 'element-ui';

function message(data,icon){
	Notification({
	   message: data,
	   customClass:'mintedMessage',
	   iconClass:icon ? 'el-icon-success':'el-icon-error',
	   duration:4500,
	   offset:88
	 });
}

export default message;