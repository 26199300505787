function copy(text){
	// 创建元素用于复制
	let copy = document.createElement('input')
	// 获取复制内容
	let content = text
	// 设置元素内容
	copy.setAttribute('value', content)
	// 将元素插入页面进行调用
	document.body.appendChild(copy)
	// 复制内容
	copy.select()
	// 将内容复制到剪贴板
	document.execCommand('copy')
	// 删除创建元素
	document.body.removeChild(copy)
}

export default copy;