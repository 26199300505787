<!-- 主页 -->
<template>
	<div class="warp">
		<Pop-Up-Mint ref="mint"></Pop-Up-Mint>
		
		<!-- 背景与粒子特效 -->
		<div class="background">
			
			<div class="background-animation" id="particles"></div>
			
			<!-- 中心内容 -->
			<div class="content">
				<div class="content-box">
					<div class="left">
						<div class="left-box">
							<div class="subTitle wow animate__animated  animate__fadeInUp">Leading the Sustainable Earning Ecosystem</div>
							<div class="title wow animate__animated  animate__fadeInUp">LEGEND OF WARRIORS</div>
							<div class="desc wow animate__animated  animate__fadeInUp">Leading the Sustainable Earning Ecosystem</div>
							<div class="mint wow animate__animated  animate__fadeInUp"><button class="hover-button" @click="mint()">Mint</button></div>
						</div>
					</div>
					<div class="right">
						<div class="animate__animated  animate__fadeInUp wow">
							<img src="../assets/images/women-with-vr.png" />
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
		<Comments></Comments>
		<Introduce></Introduce>
		<Marketplace></Marketplace>
		<Create></Create>
	</div>
</template>

<script>
	import minta from '@/tools/ethers/mint.js';
	import particlesConfig from "@/tools/particles/particlesjs-config.json";
	import { mapState,mapMutations } from 'vuex';
	import PopUpMint from "@/components/Mint.vue";
	
	import Introduce from "@/components/Index/Introduce.vue";
	import Marketplace from "@/components/Index/Marketplace.vue";
	import Create from "@/components/Index/Create.vue";
	import Comments from "@/components/Index/Comments.vue";
	
	export default {
	  name: "index",
	  components:{
		  PopUpMint,
		  Introduce,
		  Marketplace,
		  Create,
		  Comments
	  },
	  data(){
		  return{
			  show:false,
		  }
	  },
	  mounted(){
		particlesJS("particles", particlesConfig);
	  },
	  methods:{
		  mint(){
			this.$refs.mint.start();
		  } 
	  },

	};
</script>

<style scoped="scoped">
	/* fadeInUp animated */
	/* 背景的样式 */
	.background{
		background: url(../assets/images/background.jpg) center bottom / cover;
		height: 100vh;
		position: relative;
	}
	.background-animation{
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		z-index: 0;
	}
	
	/* 内容部分整体样式 */
	.content{
		padding: 0px 30px;
		width: 100%;
		box-sizing: border-box;
		height: calc(100% - 88px);
		position: absolute;
		top: 0px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
	}
	.content-box{
		max-width: 1296px;
		width: 100%;
		padding: 12px;
		display: flex;
		justify-content: center;
	}
	.content-box>div{
		width: 50%;
	}
	
	/* 右边图片样式 */
	.right{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.right img{
		width: 100%;
		height: auto;
	}
	
	/* 左边文字部分样式 */
	.left{
		text-align: left;
	}
	.left .subTitle{
		margin-top: 30px;
		margin-bottom: 20px;
		color: #8364E2;
		font-weight: 700;
	}
	.left .title{
		margin-bottom: 20px;
		font-size: 50px;
		font-weight: 700;
		color: #fff;
	}
	
	.left .desc{
		margin-bottom: 20px;
		font-size: 18px;
		line-height: 1.7em;
		font-weight: 400;
		color: #a2a2a2;;
	}
	
	.left .mint button{
		cursor: pointer;
		background-color: #8364E2;
		color: #fff;
		width: 110px;
		height: 42px;
		border-radius: 30px;
		font-family: "DM Sans";
		font-weight: 600;
	}
	
	.left{
		display: flex;
		align-items: center;
	}
	.paypopup{
		color: #cecece;
	}
	
	.box{
		height: 200px;
		text-align: center;
		margin-bottom: 10px;
	}
	.box img{
		height: 100%;
	}
	
	.info{
		/* display: flex; */
	}
	.info-item{
		padding: 5px 10px;
		margin-top: 10px;
	}
	.info-item:not(:last-child){
		border-bottom: 1px solid rgba(107, 107, 107, 0.5);
	}
	
	/* 媒体查询样式部分 */
	@media screen and (max-width: 1400px) {
		.content-box>div{
			width: 40%;
		}
	}
	
	@media screen and (max-width: 980px) {
		.content-box>div{
			width: 50%;
			
		}
	}
	
	@media screen and (max-width: 765px) {
		.right{
			display: none;
		}
		.content-box>div{
			width: 90%;
		}
	}
</style>
