<template>
	<div class="create-warp">
		<div class="create-box">
			<Title value="Create and Sell Now"></Title>
			<div class="steps">
				<div class="steps-item">
					<div class="steps-item-box wow animate__animated  animate__fadeInUp">
						<div class="icon">
							<i class="el-icon-wallet"></i>
						</div>
						<div class="title">
							<span>Set up your wallet</span>
						</div>
						<div class="content">
							<p>Once you’ve set up your wallet of choice, connect it to OpenSea by clicking the wallet icon in the top right corner. Learn about the wallets we support.</p>
						</div>
					</div>
					<div class="background-icon">
						<i class="el-icon-wallet"></i>
					</div>
				</div>
				
				<div class="steps-item">
					<div class="steps-item-box wow animate__animated  animate__fadeInUp">
						<div class="icon">
							<i class="el-icon-upload2"></i>
						</div>
						<div class="title">
							<span>Set up your wallet</span>
						</div>
						<div class="content">
							<p>Once you’ve set up your wallet of choice, connect it to OpenSea by clicking the wallet icon in the top right corner. Learn about the wallets we support.</p>
						</div>
					</div>
					<div class="background-icon">
						<i class="el-icon-upload2"></i>
					</div>
				</div>
				
				<div class="steps-item">
					<div class="steps-item-box wow animate__animated  animate__fadeInUp	">
						<div class="icon">
							<i class="el-icon-discount"></i>
						</div>
						<div class="title">
							<span>Set up your wallet</span>
						</div>
						<div class="content">
							<p>Once you’ve set up your wallet of choice, connect it to OpenSea by clicking the wallet icon in the top right corner. Learn about the wallets we support.</p>
						</div>
					</div>
					
					<div class="background-icon">
						<i class="el-icon-discount"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Title from "./Titel.vue";
	export default {
		components: {Title},
		mounted() {
		}
	}
</script>

<style scoped="scoped">
	.create-warp {
		width: 100%;
		display: flex;
		justify-content: center;
		background-color: #212428;
	}
	
	.create-box {
		max-width: 1296px;
		width: 100%;
		padding: 0px 90px 90px 90px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.steps{
		color: #fff;
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
	}
	
	.steps-item{
		width: 30%;
		background: rgba(255, 255, 255, .025);
		border-top: solid 1px rgba(255, 255, 255, .1);
		box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 30%);
		-webkit-box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 30%);
		-moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
		padding: 50px;
		box-sizing: border-box;
		border-radius: 8px;
		text-align: left;
		position: relative;
		overflow: hidden;
	}
	
	.steps-item .icon{
		width: 56px;
		height: 56px;
		border-radius: 5px;
		background-color: #8364E2;
		display: flex;
		align-items: center;
		font-size: 24px;
		justify-content: center;
	}
	
	.steps-item .title{
		outline:none;
		font-size: 20px;
		font-weight: 800;
	}
	
	.steps-item .title,
	.steps-item .content{
		margin-top: 16px;
	}
	
	.steps-item .content{
		transition: all 0.3s;
		font-size: 16px;
		color: rgb(162, 162, 162);
	}
	
	.steps-item .background-icon{
		transition: all 0.3s;
		font-size: 100px;
		position: absolute;
		z-index: 1;
		right: 20px;
		bottom: 16px;
		color: rgb(131, 100, 226,0.2);
		transform: scale(2.5) rotate(0deg);
	}
	.steps-item-box{
		width: 100%;
		z-index: 2;
		position: relative;
	}
	.steps-item:hover .background-icon{
		transform: scale(2.5) rotate(-25deg);
	}
	.steps-item:hover .content{
		color: #e3e3e3;
	}
	
	@media screen and (max-width: 1200px) {
		.create-box {
			padding: 0px 40px 90px 40px;
		}
		.steps-item{
			width: calc(33% - 10px);
		}
	}
	
	@media screen and (max-width: 980px) {
		.steps-item{
			width: calc(50% - 10px);
			margin-bottom: 20px;
		}
	}
	
	@media screen and (max-width: 770px) {
		.steps-item{
			width: 100%;
			margin-bottom: 20px;
		}
	}
</style>