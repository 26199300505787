import { render, staticRenderFns } from "./SuccessPay.vue?vue&type=template&id=97a1a624&scoped=true&"
import script from "./SuccessPay.vue?vue&type=script&lang=js&"
export * from "./SuccessPay.vue?vue&type=script&lang=js&"
import style0 from "./SuccessPay.vue?vue&type=style&index=0&id=97a1a624&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a1a624",
  null
  
)

export default component.exports