<!-- Collection页 -->
<template>
	<div class="collection-warp">
		<div class="collection">
			
			<!-- 成功的提示框 -->
			<Success-Pay ref="minted"></Success-Pay>
			
			<!-- mint组件 -->
			<Pop-Up-Mint ref="mint"></Pop-Up-Mint>
			
			<div class="banner animate__animated animate__fadeIn">
				<div class="banner-box">
					<div class="banner-title">
						Create, sell and collect digital items.
					</div>
					<div class="mint">
						<button class="hover-button" @click="mint()">Mint</button>
					</div>
				</div>
			</div>
			
			<div class="collection-box">
				
				<!-- 搜索框 -->
				<div class="title animate__animated animate__fadeIn">
					<span>My Collections</span>
				</div>
				
				<!-- 数据列表 -->
				<div class="data">
					
					<div class="data-item" v-for="(item,index) in newDataList" :key="`newdatalist-${index}`">
						<div class="data-item-box" @click="go(item)">
							<Collection-item @animationend="state=>{item.bright = state}" :bright="item.bright" :title="'Hero#'+item.token_id" :image="toObj(item.metadata,'image')"></Collection-item>
						</div>
					</div>
					
					<div class="data-item" v-for="(item,index) in dataList" :key="`datalist-${index}`">
						<div class="data-item-box" @click="go(item)">
							<Collection-item @animationend="state=>{item.bright = state}" :bright="item.bright" :title="'Hero#'+item.token_id" :image="toObj(item.metadata,'image')"></Collection-item>
						</div>
					</div>
					
				</div>
				<div v-if="nextPageStatus" class="loadmore animate__animated animate__fadeIn"><button class="hover-button" @click="nextPage()">Load more</button></div>
			</div>
		</div>
	</div>
</template>

<script>
	// 组件导入
	import CollectionItem from "@/components/CollectionItem.vue";
	import SuccessPay from "@/components/SuccessPay.vue";
	import {mintList,tokenURI,connection} from '@/tools/ethers/newIndex.js';
	import {mapState} from 'vuex';
	import PopUpMint from "@/components/Mint.vue";
	import imageName from "@/config/imageName.js"
	import baseUrl from "@/config/baseUrl.js"
	
	export default{
		name:"CollectionView",
		components:{CollectionItem,SuccessPay,PopUpMint},
		data(){
			return{
				dataList:[],	//存放数据渲染列表
				newDataList:[],	//存放更新的数据渲染列表
				cursor:'',	//用于分页
				nextPageStatus:true,	//下一页按钮显示
				SuccessPayShow:false,	//是否显示交易成功特效
				show:false,	//控制mint
				count:0,
			}
		},
		mounted(){
			//检查
			this.checkMinted();
			
			// 获取第一页
			if(this.token){
				this.nextPage()
			}
		},
		methods:{
			
			// 开始支付
			mint(){
				this.$refs.mint.start();
			},
			
			// 下一页
			async nextPage(){
				
				await mintList(this.cursor).then((res)=>{
					if(res){
						this.dataList = [...this.dataList,...res.data];
						
						if(this.cursor == ""){
							this.setLoadingData()
						}
						
						if(res.cursor){
							this.cursor = res.cursor
						}else{
							this.nextPageStatus = false;
						};
					}else{
						return;
					}

				});
			},
			
			// 数据转换且提取
			toObj(data,key){
				if(data){
					return JSON.parse(data)[key]
				}
			},
			
			// 跳转详情
			go(item){
				let data = {...item};
				data.metadata = JSON.parse(data.metadata)
				localStorage.setItem('item',JSON.stringify(data));
				this.$router.push('/item');
			},
			
			// 获取并更新数据
			update(data){
				let list = [];
				let hero = `${baseUrl.imageServerUrl}${data.quality}/${data.subLevel}/hero.png`;
				// 重构数据格式由于两组数据内容一样但格式不一样
				let metadata = {
					image:hero,
					attributes:[
						{imgUrl:hero,trait_type:'character'},
						{imgUrl:`${baseUrl.imageServerUrl}weapon/${data.weapon}.png`,trait_type:'Weapon'},
						{imgUrl:`${baseUrl.imageServerUrl}subweapon/${data.subWeapon}.png`,trait_type:'Sub Weapon'},
						{imgUrl:`${baseUrl.imageServerUrl}arm/${data.armor}.png`,trait_type:'Armor'},
						{imgUrl:`${baseUrl.imageServerUrl}gem/${data.gem}.png`,trait_type:'Gem'},
					],
					subLevel:data.subLevel,
					rarity:data.quality,
					rank:data.quality,
				}
				// 对象转JSON字符串
				metadata = JSON.stringify(metadata);
				
				// 重构数据组合
				let tmpData = {
					bright:true,	//显示发光效果
					block_number:data.blockNumber,
					token_id:data.tokenid,
					metadata:metadata,
					owner_of:data.owner_of
				}
				
				// 检查是否已经被获取到了
				for(let key in this.dataList){
					if(this.dataList[key].token_id == data.tokenid){
						// 若果获取到了把它删掉
						this.dataList.splice(key,1);
					}
				}
				
				// 添加到新数据列表
				this.newDataList.push(tmpData)
				this.newDataList = (this.quickSort(this.newDataList));
			},
		
			//检查是否显示显示成功minted的函数
			checkMinted(){
				if(this.$route.params.successpay == 'ok'){
					this.$refs.minted.open();
				}
			},
			
			// 动画结束回调函数
			animationend(idx){
				this.dataList[idx].bright = false;
			},
			
			// 打开页面时从新加载未完成的数据
			setLoadingData(){
				for(let key in this.dataList){
					if(this.dataList[key].metadata == null){
						console.log(this.dataList[key])
						this.getLoadingData(this.dataList[key].token_id+'');
					}
				}
			},
			
			//从服务器获取未更新的数据并进行更新
			async getLoadingData(id){
				try{
					let data = await tokenURI(id);
					for(let key in this.dataList){
						if(this.dataList[key].token_id == id){
							this.dataList[key].bright = true;
							this.dataList[key].metadata = JSON.stringify(data);
						}
					}
					return;
				}catch(e){
					setTimeout(()=>{
						this.getLoadingData(id);
					},1000)
				}
			},
			
			//快速排序
			quickSort(arr){
				if (arr.length <= 1) { return arr; }
				
				　　var pivotIndex = Math.floor(arr.length / 2);
				　　var pivot = arr.splice(pivotIndex, 1)[0];
				　　var left = [];
				　　var right = [];
				
				　　for (var i = 0; i < arr.length; i++){
				　　　　if (arr[i].token_id > pivot.token_id) {
				　　　　　　left.push(arr[i]);
				　　　　} else {
				　　　　　　right.push(arr[i]);
				　　　　}
				　　}
				　　return this.quickSort(left).concat([pivot], this.quickSort(right));
			}
		},
		computed:{
			...mapState(['token','newData','provider']),
		},
		watch:{
			token(val){
				this.nextPageStatus = true;
				this.cursor = '';
				this.dataList = [];
				this.newDataList = [];
				this.nextPage();
			},
			newData:{
			      handler(){
					  this.update(this.newData);
			      },
			      deep:true
			},
			state(val){
				if(val){
					this.setLoadingData();
				}
			}
		},
		destroyed() {
			clearInterval(this.timer);
		},
	}
</script>

<style scoped="scoped">
	/* 页面容器用于修改颜色 */
	.collection-warp{
		width: 100%;
		min-height: 100vh;
		height: 100%;
		background: #212428;
	}
	.collection-warp::before{
		content: '';
		display: block;
		width: 100%;
		height: 88px;
		background-color: #212428;
	}
	
	/* 中间内容部分 */
	.collection{
		width: 100%;
		box-sizing: border-box;
		/* padding-top: 88px; */
		min-height: 800px;
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) 0% 0% / cover,
					url("../assets/images/collection_background.png") no-repeat center center / cover;
	}
	
	/* 集合页面banner */
	.banner{
		height: 240px;
		display: flex;
		align-items: center;
		justify-content: center;
		/* background: url(../assets/images/collection.png) no-repeat center top / cover; */
		padding: 0px 20px;
		box-sizing: border-box;
	}
	
	/* 集合页面banner标题 */
	.banner-title{
		min-height: 40px;
		width: 100%;
		font-size: 25px;
		color: #dedede;
	}
	
	/* 集合页面的mint按钮 */
	.mint{
		height: 40px;
		margin-top: 10px;
	}
	.mint button{
		width: 110px;
		height: 38px;
		border-radius: 20px;
		color: #fff;
		font-weight: 500;
		background-color: #8364E2;
	}
	
	/* 内容限位容器 */
	.collection-box{
		max-width: 1296px;
		width: 100%;
		margin: auto;
		padding: 12px;
		padding: 0px 30px;
		padding-top: 60px;
		box-sizing: border-box;
	}
	
	/* 搜索框限位容器 */
	.title{
		padding-left: 12px;
		width: 100%;
		display: flex;
		font-size: 21px;
		font-weight: 600;
		color: #e5e5e5;
	}
	
	/* 数据列表样式 - Strart */
	.data{
		display: flex;
		flex-wrap: wrap;
		margin-top: 10px;
	}
	
	.data-item{
		/* transition: all 0.3s; */
		width: 25%;
		padding: 12px;
		box-sizing: border-box;
	}
	
	
	.data-item-box{
		min-height: 400px;
	}
	/* 数据列表样式 - End */
	
	/* 加载更多的按钮样式 */
	.loadmore button{
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		padding: 8px 20px;
		border-radius: 50px;
		margin: 20px 0px;
		background-color: rgb(131, 100, 226);
	}
	
	/* 没有数据显示的字体样式 */
	.not-data{
		font-size: 21px;
		color: #fff;
		font-weight: 700;
	}
	
	/* 响应式代码 */
	@media screen and (max-width: 992px) {
		.data-item{
			width: 50%;
		}
	}
	
	@media screen and (max-width: 576px) {
		.data-item{
			width: 100%;
		}
	}
</style>
