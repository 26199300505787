<template>
  <div>
    <button
        @click="getTokens()"
        style="
              padding: 16px 50px;
              background-color: rgb(131, 100, 226);
              color: #FFFFFF;
              font-weight: 600;
              border-radius: 50px;
          ">
      <span style="font-size: 21px;margin-right: 5px">Give me {{tokensName}}</span>

      <span v-show="!$store.state.giveMeTokenLoading">
        <span v-show="$store.state.token != ''">
           <span v-show="getTimeText != 'now' && getTimeText != ''">
             (Next time : <span>{{ getTimeText }}</span>)
           </span>
        </span>
      </span>

      <span v-show="$store.state.giveMeTokenLoading">
        <i class="el-icon-loading" style="font-size: 21px"></i>
      </span>
    </button>
  </div>
</template>

<script>
import {getCurrentAccount, getTokens} from "@/tools/ethers/newIndex";
import {Message} from "element-ui";
import store from "@/store";

export default {
  name: "GetTokens",
  props:{
    "tokensName":{}
  },
  data(){
    return{
      nextTime:'',
      connectionState:null,
      getTokensState:false
    }
  },
  mounted() {

  },
  methods:{
    async getTokens(){
      if(this.getTokensState){ return }
      this.getTokensState = true

      if(store.state.token == ''){
        Message.warning("Please connect your wallet first.")
        this.getTokensState = false
        return
      }
      if(store.state.nextTime == ''){
        this.getTokensState = false
        return;
      }
      let time1 = new Date().getTime()
      let time2 = new Date(store.state.nextTime).getTime()
      if(time1 < time2){
        Message.warning("It's not the appointed time yet.")
        this.getTokensState = false
        return;
      }
      await getTokens()

      this.getTokensState = false
    }
  },
  computed:{
    getTimeText(){
      let getTokensOk = "1970-01-01 08:00:00"
      let time = this.$store.state.nextTime
      console.log(time)
      if (time == undefined) {

        return 'null'
      }else if (time == getTokensOk){

        return 'now';
      }else{

        let time1 = new Date().getTime()
        let time2 = new Date(time).getTime()
        if(time1 < time2){
          return time
        }else{
          return 'now';
        }

      }
    }
  }
}
</script>

<style scoped>

</style>
