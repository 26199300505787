export default [
    {
        "id": "0xaeed...99f3",
        "point": "1993",
        "reward": "200 XYJ"
    },
    {
        "id": "0xe2f4...d066",
        "point": "1977",
        "reward": "150 XYJ"
    },
    {
        "id": "0xd6f8...7080",
        "point": "1949",
        "reward": "150 XYJ"
    },
    {
        "id": "0x57c6...961a",
        "point": "1917",
        "reward": "100 XYJ"
    },
    {
        "id": "0xc9b3...db00",
        "point": "1850",
        "reward": "100 XYJ"
    },
    {
        "id": "0x2e1f...5c0a",
        "point": "1828",
        "reward": "100 XYJ"
    },
    {
        "id": "0x34bd...c379",
        "point": "1738",
        "reward": "100 XYJ"
    },
    {
        "id": "0xbe21...6954",
        "point": "1718",
        "reward": "100 XYJ"
    },
    {
        "id": "0xd00e...6815",
        "point": "1696",
        "reward": "100 XYJ"
    },
    {
        "id": "0x2787...7992",
        "point": "1665",
        "reward": "100 XYJ"
    },
    {
        "id": "0x5aed...4150",
        "point": "1652",
        "reward": "50 XYJ"
    },
    {
        "id": "0x5465...3af3",
        "point": "1643",
        "reward": "50 XYJ"
    },
    {
        "id": "0x0ed7...1340",
        "point": "1614",
        "reward": "50 XYJ"
    },
    {
        "id": "0x778d...be77",
        "point": "1565",
        "reward": "50 XYJ"
    },
    {
        "id": "0xb5c7...b1ed",
        "point": "1514",
        "reward": "50 XYJ"
    },
    {
        "id": "0x22b1...15cf",
        "point": "1508",
        "reward": "50 XYJ"
    },
    {
        "id": "0xe9a1...a98e",
        "point": "1479",
        "reward": "50 XYJ"
    },
    {
        "id": "0xc153...f47d",
        "point": "1443",
        "reward": "50 XYJ"
    },
    {
        "id": "0x284c...770e",
        "point": "1416",
        "reward": "50 XYJ"
    },
    {
        "id": "0xe491...edc5",
        "point": "1371",
        "reward": "50 XYJ"
    },
    {
        "id": "0x7ace...2fa6",
        "point": "1357",
        "reward": "50 XYJ"
    },
    {
        "id": "0xe17e...f2eb",
        "point": "1354",
        "reward": "50 XYJ"
    },
    {
        "id": "0x5841...b22c",
        "point": "1353",
        "reward": "50 XYJ"
    },
    {
        "id": "0x712e...3dfc",
        "point": "1347",
        "reward": "50 XYJ"
    },
    {
        "id": "0x0d90...5f22",
        "point": "1313",
        "reward": "50 XYJ"
    },
    {
        "id": "0xe0d9...9547",
        "point": "1306",
        "reward": "50 XYJ"
    },
    {
        "id": "0xb478...07cf",
        "point": "1305",
        "reward": "50 XYJ"
    },
    {
        "id": "0x824d...1e7f",
        "point": "1295",
        "reward": "50 XYJ"
    },
    {
        "id": "0xe53d...192c",
        "point": "1290",
        "reward": "50 XYJ"
    },
    {
        "id": "0x9e78...6ba6",
        "point": "1264",
        "reward": "50 XYJ"
    },
    {
        "id": "0x990c...bb5a",
        "point": "1251",
        "reward": "25 XYJ"
    },
    {
        "id": "0x913a...94ef",
        "point": "1240",
        "reward": "25 XYJ"
    },
    {
        "id": "0x6191...303a",
        "point": "1237",
        "reward": "25 XYJ"
    },
    {
        "id": "0x6b2d...39c9",
        "point": "1216",
        "reward": "25 XYJ"
    },
    {
        "id": "0x77f5...93bd",
        "point": "1196",
        "reward": "25 XYJ"
    },
    {
        "id": "0x5d82...7c66",
        "point": "1163",
        "reward": "25 XYJ"
    },
    {
        "id": "0xb2e4...084d",
        "point": "1136",
        "reward": "25 XYJ"
    },
    {
        "id": "0xaa2d...f54d",
        "point": "1064",
        "reward": "25 XYJ"
    },
    {
        "id": "0x887b...d1bf",
        "point": "1064",
        "reward": "25 XYJ"
    },
    {
        "id": "0xaa50...6e9e",
        "point": "1051",
        "reward": "25 XYJ"
    },
    {
        "id": "0xa6f0...dd5b",
        "point": "1008",
        "reward": "25 XYJ"
    },
    {
        "id": "0xf8f8...a392",
        "point": "994 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0xe5bf...51ce",
        "point": "994 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0x881f...1215",
        "point": "975 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0xfecd...854a",
        "point": "958 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0x4985...d402",
        "point": "957 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0x4a1d...01cc",
        "point": "949 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0xc688...1851",
        "point": "946 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0x2965...5379",
        "point": "925 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0x50a1...9845",
        "point": "877 ",
        "reward": " 25 XYJ"
    },
    {
        "id": "0x339c...4eda",
        "point": "8112",
        "reward": "5 XYJ"
    },
    {
        "id": "0xff96...4e1e",
        "point": "8072",
        "reward": "5 XYJ"
    },
    {
        "id": "0x4438...8440",
        "point": "8012",
        "reward": "5 XYJ"
    },
    {
        "id": "0x633d...fbcd",
        "point": "7852",
        "reward": "5 XYJ"
    },
    {
        "id": "0x2636...ac39",
        "point": "7802",
        "reward": "5 XYJ"
    },
    {
        "id": "0xa47c...631c",
        "point": "7792",
        "reward": "5 XYJ"
    },
    {
        "id": "0xbae5...623b",
        "point": "7692",
        "reward": "5 XYJ"
    },
    {
        "id": "0xaf6c...a523",
        "point": "7642",
        "reward": "5 XYJ"
    },
    {
        "id": "0xbc9a...81bb",
        "point": "7622",
        "reward": "5 XYJ"
    },
    {
        "id": "0xb3c3...2014",
        "point": "7112",
        "reward": "5 XYJ"
    },
    {
        "id": "0x137f...e528",
        "point": "7032",
        "reward": "5 XYJ"
    },
    {
        "id": "0x40aa...2b46",
        "point": "6932",
        "reward": "5 XYJ"
    },
    {
        "id": "0xb39d...0278",
        "point": "6782",
        "reward": "5 XYJ"
    },
    {
        "id": "0x84f2...9dcf",
        "point": "6772",
        "reward": "5 XYJ"
    },
    {
        "id": "0x66ac...5911",
        "point": "6702",
        "reward": "5 XYJ"
    },
    {
        "id": "0x966e...d078",
        "point": "6362",
        "reward": "5 XYJ"
    },
    {
        "id": "0x1a87...a6eb",
        "point": "6352",
        "reward": "5 XYJ"
    },
    {
        "id": "0x4b2d...436e",
        "point": "6072",
        "reward": "5 XYJ"
    },
    {
        "id": "0xd621...7703",
        "point": "6022",
        "reward": "5 XYJ"
    },
    {
        "id": "0x4e9d...7bb7",
        "point": "5982",
        "reward": "5 XYJ"
    },
    {
        "id": "0x640f...c0d9",
        "point": "5872",
        "reward": "5 XYJ"
    },
    {
        "id": "0x805f...ad5a",
        "point": "5772",
        "reward": "5 XYJ"
    },
    {
        "id": "0x9e22...e138",
        "point": "5752",
        "reward": "5 XYJ"
    },
    {
        "id": "0xb823...1a9a",
        "point": "5692",
        "reward": "5 XYJ"
    },
    {
        "id": "0x846b...4cf9",
        "point": "5502",
        "reward": "5 XYJ"
    },
    {
        "id": "0xc8be...11e1",
        "point": "5442",
        "reward": "5 XYJ"
    },
    {
        "id": "0xfd9a...381c",
        "point": "5092",
        "reward": "5 XYJ"
    },
    {
        "id": "0x051d...6a0a",
        "point": "5062",
        "reward": "5 XYJ"
    },
    {
        "id": "0x32fd...3616",
        "point": "4872",
        "reward": "5 XYJ"
    },
    {
        "id": "0x2b8d...7d96",
        "point": "4812",
        "reward": "5 XYJ"
    },
    {
        "id": "0x32ce...bf1c",
        "point": "4670",
        "reward": " XYJ"
    },
    {
        "id": "0x3e40...f172",
        "point": "4420",
        "reward": " XYJ"
    },
    {
        "id": "0xb170...ce1e",
        "point": "4340",
        "reward": " XYJ"
    },
    {
        "id": "0x047c...c338",
        "point": "3650",
        "reward": " XYJ"
    },
    {
        "id": "0x69cb...1a05",
        "point": "3580",
        "reward": " XYJ"
    },
    {
        "id": "0xbb98...858f",
        "point": "3570",
        "reward": " XYJ"
    },
    {
        "id": "0xc7a4...a137",
        "point": "3500",
        "reward": " XYJ"
    },
    {
        "id": "0x5df3...cc58",
        "point": "3220",
        "reward": " XYJ"
    },
    {
        "id": "0xd764...7527",
        "point": "3080",
        "reward": " XYJ"
    },
    {
        "id": "0xc62a...597d",
        "point": "2830",
        "reward": " XYJ"
    },
    {
        "id": "0x8f77...4ef5",
        "point": "2410",
        "reward": " XYJ"
    },
    {
        "id": "0xa098...023c",
        "point": "2140",
        "reward": " XYJ"
    },
    {
        "id": "0x5a0e...4980",
        "point": "1960",
        "reward": " XYJ"
    },
    {
        "id": "0x4947...3cba",
        "point": "1790",
        "reward": " XYJ"
    },
    {
        "id": "0xa37f...f20f",
        "point": "970 ",
        "reward": "XYJ"
    },
    {
        "id": "0x1cd8...374f",
        "point": "850 ",
        "reward": "XYJ"
    },
    {
        "id": "0xdde3...9403",
        "point": "260 ",
        "reward": "XYJ"
    },
    {
        "id": "0x0cf9...97d5",
        "point": "240 ",
        "reward": "XYJ"
    },
    {
        "id": "0x2e92...cc9d",
        "point": "6",
        "reward": " 0 XYJ"
    },
    {
        "id": "0x10e0...81a6",
        "point": "5",
        "reward": " 0 XYJ"
    }
];