import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

// fortawesome图标
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
library.add(fas, far, fab);
 
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);

import wow from 'wowjs';
Vue.prototype.$wow=wow;

// 过滤器
Vue.filter('token', function (value) {
	if(value == '' || value == null){
		return '';
	}
	return `${value.substr(0, 6)}...${value.substr(value.length - 4)}`;
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
