<!-- 导航 -->
<template>
	<div class="nav" :class="{notTop:navStatus==true}">
		<div class="nav-box">
		<!--  -->

			<!-- logo -->
		    <div class="logo">
				<img src="../assets/images/logo.png" />
				<div>Legend Of Warriors</div>
			</div>

			<!-- 导航列表(右侧部分) -->
			<div class="right">

				<ul class="nav-list">
					<li class="animate__animated animate__fadeIn" v-for="key in menu" v-show="key.hidden" @click="jump(key.href)"><a class="tips_line" href="#">{{key.title}}</a></li>
				</ul>

				<!-- 秘钥 -->
				<div class="walletKey animate__animated animate__fadeIn">
					<el-popover
					    width="200"
					    trigger="hover"
						:disabled="walletKey=='Connect Wallet'"
						popper-class="popper"
						:visible-arrow="false"
						>
						<Prover ref="proverss" :token="walletKey" :num="xyzNum"></Prover>
						<button slot="reference" class="hover-button" @click="connection()">
							<img v-show="icon" class="waller-icon" :src="icon">
							<!--  -->
							<span>{{walletKey}}</span>
						</button>
					  </el-popover>

				</div>

				<!-- 折叠菜单的按钮 -->
				<div class="bars" @click="fold=!fold">
					<div class="bars-box"></div>
					<font-awesome-icon icon="fa-solid fa-bars"/>
				</div>
			</div>
		</div>

		<!-- 折叠菜单 -->
		<ul class="nav-list-fold" :style="{height:fold?(approvedStatus?`${(menu.length)*50}px`:`${(menu.length-1)*50}px`):'0px'}">
			<li class="nav-list-item" v-for="key in menu" v-show="key.hidden" @click="jump(key.href)"><a href="#">{{key.title}}</a></li>
		</ul>
	</div>
</template>

<script>
	import {connection,checkConnection,accountsChanged,getBalance,getCachedProvider,checkNetwork,getBalanceNum} from '@/tools/ethers/newIndex.js';
	import { mapState,mapMutations } from 'vuex';
	import Prover from "./Popver.vue";
    import Vue from 'vue';

	export default{
		name:"NavList",
		components:{Prover},
		data(){
			return{
				menu:[
					{title:'Home',href:'/',hidden:true},
					{title:'Rarity',href:'/rarity',hidden:true},
					{title:'Tournament',href:'/tournament',hidden:true},
					{title:'Ranking',href:'/ranking',hidden:true},
					{title:'FAQ',href:'/faq',hidden:true},
					{title:'Collections',href:'/collection',hidden:false},
				],
				fold:false,	 //用于控制折叠菜单的变量
				navStatus:false,	//用户控制滚动条是否透明的
				walletKey:'Connect Wallet' ,//钱包地址
				approvedStatus:false,		//授权状态
				num:0,
			}
		},
		mounted () {

			// 判断是否需要执行连接
			if(getCachedProvider()) {
				this.connection();
			}

			// 创建一个监听事件
			window.addEventListener('scroll', this.handleScroll, true);

			// 监听页面大小变化调整页面效果
			window.onresize = ()=>{
				return(()=>{
					// 但他<992时让折叠菜单隐藏
					if(document.body.clientWidth > 992){
						this.fold = false;
					}
				})()
			}

			// 点击非导航栏状态时退出导航
			document.addEventListener('click',(e)=>{
				if(e.target.className != 'bars' && e.target.className !='bars-box'){
					if(e.target.className != 'nav-list-fold' && e.target.className !=  'nav-list-item'){
						this.close();
					}
				}
			})
		},
		methods:{
			 	//滚动事件
			    handleScroll() {
			      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			      var offsetTop = document.body.offsetTop // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
			      if(scrollTop > 0 && this.navStatus == false){
					  this.navStatus = true;
            }
            if(scrollTop == 0){
              this.navStatus = false;
            }
			    },

				//登入
				connection(){
					 if(!this.approvedStatus){
						 connection((res,icon)=>{
						 	// 登入后修改数据
						 	let filter = Vue.filter('token');
						 	let token = filter(res[0])
							this.setRealToken(res[0])
						 	this.setToken(token);
							checkNetwork().then(async res=>{
								if(res){
									getBalance().then(res=>this.setXyzNum(res));
									let balanceNum = await getBalanceNum();
									this.setEthNum(balanceNum)
								}
							});
							if(icon){
								this.setIcon(icon);
							}
						 })
					 }
				 },

				 // 关闭菜单
				 close(){
					 this.fold = false;
				 },
				 openPopver(){
					 if(this.approvedStatus){
						 this.$refs["proverss"].open();
					 }
				 },
				 closePopver(){
					 this.$refs["proverss"].hidden();
				 },
				 jump(href){
					 setTimeout(()=>{
					 	this.$router.push({
					 		path: href,
					 	})
					 },200)
				 },
				 ...mapMutations(['setToken','web3Modal','setXyzNum','setIcon','setRealToken','setEthNum'])
		},
		computed:{
			...mapState(['token','provider','xyzNum','icon']),
		},
		watch:{
			walletKey(val){
				if(val!='Connect Wallet'){
					this.approvedStatus = true;
					this.menu[this.menu.length-1].hidden = true;
				}
			},
			token(val){
				this.walletKey = val;
			}
		}
	}
</script>

<style scoped="scoped">

	/* 导航栏基本样式 */
	.nav{
		padding: 30px;
		box-sizing: border-box;
		position: fixed;
		top: 0px;
		z-index: 99;
		height: 88px;
		width: 100vw;
		background-color: rgba(0,0,0,0);
		transition: background-color 0.6s;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* 滚动页面后的导航栏附加样式 */
	.notTop{
		background: rgba(33, 36, 40, .9) !important;
		box-shadow: 0 4px 20px 0 rgba(10,10,10,0.8) !important;
	}

	/* 导航栏居中限位的样式 */
	.nav-box{
		padding: 12px 0px;
		box-sizing: border-box;
		max-width: 1320px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
	}

	/* logo样式 */
	.logo{
		display: flex;
		align-items: center;
		height: 100%;
		color: #ececec;
		font-weight: 600;
		font-size: 21px;
	}
	.logo>div{
		margin-bottom: 4px;
	}
	.logo img{
		height: 60px;
		margin-right: 10px;
	}

	/* 导航栏列表样式 */
	.nav-list{
		display: flex;
		margin: 0px;
		padding: 0px;
		padding-right: 6px;
		list-style: none;
		align-items: center;
	}
	.nav-list li>a{
		display: block;
		padding:18px;
		color: #fff;
		font-weight: 800;
		font-size: 14px;
		text-decoration: none;
	}

	/* 秘钥样式 */
	.walletKey{
		margin-left: 10px;
	}
	.waller-icon{
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
	.walletKey button span{
		margin-top: 2px;
	}
	.walletKey button{
		border: 0px;
		background-color: rgb(131, 100, 226);
		padding: 8px 40px;
		line-height: 26px;
		color: #fff;
		font-weight: 800;
		font-size: 14px;
		border-radius: 30px;
		display: flex;
		align-items: center;
	}

	/* 导航列表分区限位 */
	.right{
		width: 680px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	/* 折叠按钮样式 */
	.bars{
		-webkit-user-select: none;
		background-color: aliceblue;
		color: #fff;
		background-color: rgb(64, 63, 131);
		width: 24px;
		height: 24px;
		padding: 4px;
		margin-left: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all .1s;
		overflow: hidden;
		width: 0px;
		padding: 0px;
		cursor: pointer;
		position: relative;
	}

	.bars-box{
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: #000000;
		opacity: 0;
	}

	/* 折叠菜单样式 */
	.nav-list-fold{
		transition: height 0.3s;
		margin: 0px;
		width: 100vw;
		position: fixed;
		top: 88px;
		left: 0px;
		overflow: hidden;
		background-color: #212428;
		/* height: 126px; */
		height: 0px;
	}

	.nav-list-fold li{
		height: 48px;
		padding: 10px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		text-align: left;
	}
	.nav-list-fold li a{
		color: rgb(221, 221, 221);
		font-size: 14px;
	}

	/* 导航栏提示线特效 */
	.tips_line{
		position: relative;
		overflow: hidden;
	}
	.tips_line::after{
		content: '';
		display: block;
		position: absolute;
		transition: width 0.8s;
		height: 2px;
		width: 0px;
		background-color: rgb(131, 100, 226);
		bottom: 15px;
		left: 18px;

	}
	.tips_line:hover::after{
		width: calc(100% - 36px);
	}


	/* 媒体查询 */
	@media screen and (max-width: 992px) {

		/* 隐藏原有导航栏 */
		.nav-list{
			display: none;
		}
		.right{
			width: 300px;
		}
		/* 显示折叠菜单按钮 */
		.bars{
			width: 24px;
			padding: 4px;
		}

		/* 修改导航栏背景色 */
		.nav{
			background-color: #212428;
		}

	}

	@media screen and (max-width: 992px) {



	}


	/* 媒体查询 */
	@media screen and (max-width: 1100px) {
		.logo>div{
			display: none;
		}
	}

	/* 媒体查询 */
	@media screen and (max-width: 500px) {

		.walletKey button{
			padding: 2px 10px 2px 10px !important;
			font-size: 13px;
			border-radius: 6px;
		}

		.logo{
			/* width: 300px; */
		}

		.logo>img{
			margin: 0px;
		}
	}
</style>
<style>
	.popper{
		position: absolute;
		z-index: 9;
		width:220px;
		height: 200px;
		right: 0px;
		top: 70px;
		background-color: rgba(33, 36, 40, 1) !important;
		border: 0px !important;
		color: #fff;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.8) !important;
		border-radius: 5px;
		text-align: left;
	}


</style>
