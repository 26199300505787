<template>
	<transition name='tipslist' enter-active-class="animate__fadeInDown" leave-active-class="animate__fadeOutUp" >
		<div class="successPay animate__animated" v-show="show">
			<div class="container" ref="container">
				<div class="fire" v-for="key in bubble" :style="{left:key.left,bottom:key.bottom,background:key.color,opacity:key.opacity}"></div>
			</div>
			<div class="box">
				<div class="center-box">
					<div class="text">Minted!</div>
					<div><button class="close hover-button" @click="show = false">Close</button></div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
	export default{
		data(){
			return{
				bubble:[],
				show:false
    };
		},
		methods:{

			//随机颜色
			getColor(){
				var color = "#" + parseInt(parseInt("ffffff", 16) * Math.random()).toString(16).padStart(6, 0);
				return color;
			},

			// 创建球体
			createBubble(){
				this.bubble = [];
				let bubble = [];
				for(let i = 0;i<100;i++){
					let tmp = {
						color: this.getColor(),
						left: '240px',
						bottom: '100px',
						opacity:1
					}
					bubble.push(tmp);
				}
				this.bubble = bubble;
			},

			// 发射球体
			launch(){
				setTimeout(()=>{
					for(let key in this.bubble){
						this.bubble[key].left = `${Math.floor(Math.random() * 501)}px`;
						this.bubble[key].bottom = `${Math.floor(Math.random() * 301)}px`;
						this.bubble[key].opacity = 0;
					}
				},1000)
				console.log(this.$refs.container)
			},

			//显示窗体
			open(){
				if(this.show) return;
				this.show = true;

				// 创建气泡
				this.createBubble();

				// 发射气泡
				this.launch();

				// 退出
				setTimeout(()=>{
					this.close();
				},2500)
			},
			//关闭窗体
			close(){
				if(!this.show) return;
				this.bubble = [];
				this.show = false;
			}
		}
	}
</script>

<style scoped="scoped">
	.successPay{
		width: 500px;
		height: 300px;
		position: fixed;
		top: calc(50vh - 150px);
		left: calc(50vw - 250px);
		z-index: 999;
		box-sizing: border-box;
		background-color: #212428;
		border: 1px solid rgba(86, 86, 86, 0.6);
		box-shadow: 0px 0px 10px rgba(131, 131, 131, 0.2);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

	.successPay .text{
		user-select: none;
		color: #fff;
		font-size: 38px;
		font-weight: 600;
	}
	.box{
		width: 100%;
		height: 100%;
	}
	.center-box{
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
	}
	.center-box>div{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.close{
		margin-top: 20px;
		width: 200px;
		height: 46px;
		border-radius: 30px;
		background-color: rgb(131, 100, 226);
	}

	/* 小球样式 */
	.fire {
		transition-timing-function:cubic-bezier(0,0,1,1);
		transition: 1s;
		width: 10px;
		background: white;
		height: 10px;
		border-radius: 50%;
		position: absolute;
		bottom: 100px;
		left: 240px;
	}

	/* 特效显示框样式 */
	.container {
		margin: 0 auto;
		width: 500px;
		height: 300px;
		position: absolute;
		overflow: hidden;
		z-index: -1;
	}
</style>
