<template>
	<div class="comment-warp">
		<div ref="comment-box" class="comment-box" :class="{right:direction}" :style="{marginLeft: direction?'-50%':'0px'}">
			<template v-for="key in 2">
				<div class="comment-item" v-for="(item,idx) in list">
					<div class="item-icon">
						<img class="item-img" :src="getIconUrl(icon[idx])" />
					</div>
					<div class="item-text">{{item}}</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			list:{
				default:[]
			},
			direction:{
				default:false
			}
		},
		data(){
			return{
				icon:[]
			}
		},
		mounted() {

			// console.log(this.$refs["comment-box"].clientWidth)

			let arr = [];
			for(let i=1;i<=12;i++){arr.push(i)}
			for (let i = 0; i < arr.length; i++) {
			        let iRand = parseInt(arr.length * Math.random());
			        let temp = arr[i];
			        arr[i] = arr[iRand];
			        arr[iRand] = temp;
			}
			this.icon = arr;
		},
		methods:{
			getIconUrl(src){
				return ''+require("../../assets/images/author/author-"+src+".jpg");
			}
		}
	}
</script>

<style scoped="scoped">
	.comment-warp{
		width: 100%;
		overflow: hidden;
		height: 60px;
		display: flex;
	}
	.comment-box{
		display: flex;
		align-items: center;
		animation-name: left;
		animation-duration: 18s;
		animation-iteration-count: infinite;
	}
	.comment-item{
		display: flex;
		height: 30px;
		align-items: center;
		padding: 0px 20px 0px 5px;
		box-sizing: border-box;
		border-radius: 50px;
		background: rgba(255,255,255,0.1);
		color: rgb(162, 162, 162);
		box-shadow: 0px 0px 5px rgba(255,255,255,0.5);
		margin: 0px 10px;
	}
	.item-text{
		white-space:nowrap;
	}
	.item-img{
		height: 100%;
	}
	.item-icon{
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		margin-right: 10px;
		border-radius: 50px;
		overflow: hidden;
	}

	.right{
		animation-name: right !important;
	}

	@keyframes left {
	  0% {margin-left: 0%;}
	  100% {margin-left: -50%;}
	}

	@keyframes right {
	  0% {margin-left: -50%;}
	  100% {margin-left: 0%;}
	}
</style>
