import { render, staticRenderFns } from "./CollectionItemView.vue?vue&type=template&id=1bd7bf98&scoped=true&"
import script from "./CollectionItemView.vue?vue&type=script&lang=js&"
export * from "./CollectionItemView.vue?vue&type=script&lang=js&"
import style0 from "./CollectionItemView.vue?vue&type=style&index=0&id=1bd7bf98&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd7bf98",
  null
  
)

export default component.exports