<template>
	<div class="marketplace-warp">
		<div class="marketplace-box">
			<Title value="Marketplace"></Title>
			<div class="content wow animate__animated  animate__fadeInUp">
				Where players can trade, buy, and lend necessary equipment. In addition, NFT characters can be easily
				traded
				betweenplayers.
			</div>
			<div class="wow animate__animated  animate__fadeInUp"><img src="../../assets/images/market_place.png"></div>
		</div>
	</div>
</template>

<script>
	import Title from "./Titel.vue";
	export default {
		components: {
			Title
		}
	}
</script>

<style scoped="scoped">
	img {
		width: 100%;
	}

	.marketplace-warp {
		width: 100%;
		display: flex;
		justify-content: center;
		background-color: #212428;
	}

	.marketplace-box {
		max-width: 1296px;
		width: 100%;
		padding: 90px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.marketplace-box>div {
		width: 100%;
	}
	.content{
		text-align: left;
		color: rgb(162, 162, 162);
		font-size: 16px;
		margin-bottom: 20px;
	}
	
	
	@media screen and (max-width: 1200px) {
		.marketplace-box {
			padding: 90px 40px 90px 40px;
		}
	}
</style>
