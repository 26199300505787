import {ethers} from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import contractData from "./data2/index.js";
import net from "./net/index.js";
import axios from 'axios';
import store from "@/store";
import moment from "moment";
import {Notification} from 'element-ui'

const tokenAbi = contractData.tokenAbi;
const mintContractAbi = contractData.mintContractAbi;
const erc20ContractAddress = contractData.erc20ContractAddress;
const dbContractAddress = contractData.dbContractAddress;
const nftAddress = contractData.nftAddress;
const mintContractAddress = contractData.mintContractAddress;
const nftAbi = contractData.nftAbi;
const erc20ContractAbi = contractData.erc20ContractAbi
const net56 = net.net56;
const net97 = net.net97;

const tokensAddress = contractData.TetherTokenAddress
const TokenFaucetAbi = contractData.TokenFaucetAbi
const TokenFaucetAddress = contractData.TokenFaucetAddress

// 网络切换控制变量
var isTestnet = true;

// 可连接钱包配置信息
const providerOptions = {
	injectrd:{
		display:{},
		package:null
	},
	walletconnect: {
		package: WalletConnectProvider,
		options: {
			rpc: {
				// 97: 'https://speedy-nodes-nyc.moralis.io/689ca50e3e5cb079fef8d87a/bsc/testnet',
				// 97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
				97: 'https://patient-wispy-lake.bsc-testnet.discover.quiknode.pro/bc2c152931aa95fd853dbe4e2849b01266a62d4d/',
				1: 'https://mainnet.infura.io/v3/84c982aac7b04273a1069cb2f34fa487'
			},
		}
	},

	coinbasewallet: {
		package: CoinbaseWalletSDK,
		options: {
			rpc: {
				97: 'https://patient-wispy-lake.bsc-testnet.discover.quiknode.pro/bc2c152931aa95fd853dbe4e2849b01266a62d4d/',
				1: 'https://mainnet.infura.io/v3/84c982aac7b04273a1069cb2f34fa487'
			},
			darkMode: false
		}
	},

	// binancechainwallet: {
	// 	package: true
	// },

};

const web3Modal = new Web3Modal({
	cacheProvider: true,
	providerOptions,
	theme: "dark",
});

function getCachedProvider(){
	return web3Modal.cachedProvider;
}

// 连接
async function connection(callback=()=>{}) {
	var instance = await web3Modal.connect();
	var provider = new ethers.providers.Web3Provider(instance);
	store.commit("setInstancel",instance);
	store.commit("setProvider",provider);
	store.commit("setWeb3Modal",web3Modal);

	var icon = '';
	if(instance.isWalletConnect){icon='/images/walletconnect.svg'}
	if(instance.isCoinbaseWallet){icon='/images/coinbasewallet.svg'}
	if(instance.isMetaMask){icon='/images/metamask.svg'}
	// console.log(instance)
	getCurrentAccount().then(res=>{
		callback(res,icon)

		setTimeout(()=>{addNetwork();},1000)
	})
	await setNextTime()
	accountsChanged(callback)
	listening();
}

// 连接监听
async function onConnect(){
	store.state.provider.provider.on("connect", (info) => {
	  console.log(info);
	});
}

// 获取当前用户
async function getCurrentAccount() {
	return await store.state.provider.provider.request({
		method: 'eth_accounts'
	})
}

// Web3Modal断开连接
async function logoutOfWeb3Modal() {
	await web3Modal.clearCachedProvider();
	if (store.state.provider && store.state.provider.provider && typeof store.state.provider.provider.disconnect == "function") {
		await store.state.provider.provider.disconnect();
	}
	setTimeout(() => {
		window.location.reload();
	}, 1);
};

// 账户改变
function accountsChanged(callback=()=>{}){
	store.state.provider.provider.on("accountsChanged", async (accounts) => {
		regainProvider()
		setNextTime()
		callback(accounts)
	});
}

// 检查是否连接
async function checkConnection(){
	return new Promise(async (resolve,reject) => {
		if(store.state.instance){
			// 已连接
			let accout = await getCurrentAccount();
			resolve(accout);
		}else{
			// 未连接
			reject();
		}
	});
}

//检查网络
async function checkNetwork(){
	try{
		let signer = await store.state.provider.getSigner();

		//获取当前网络状态
		let chainId = await signer.getChainId();

		console.log('当前网络状态', chainId);

		// 查看是否为测试网络如果不是执行切换网络的函数
		if (chainId != 97) {
			return false;
		}else{
			return true;
		}
	}catch(e){
		// console.log('当前网络状态 catch', chainId);
		return false;
	}
}

//添加测试网络或者切换网络
async function addNetwork(callback=()=>{}) {

	// 获取网络id
	let signer = store.state.provider.getSigner();
	let netID = await signer.getChainId();

	//判断连接到哪一个网络
	var params;
	if (isTestnet == false) {
		if (netID == "56") {
			return;
		} else {
			params = net56
		}
	} else {
		if (netID == "97") {
			return;
		} else {
			params = net97
		}
	}
	// 连接网络
	try{
	    let netRes = await store.state.provider.provider.request({
	      method: "wallet_addEthereumChain",
	      params: [
	        {
	          chainId: params[0].chainId,
	          chainName: params[0].chainName,
	          rpcUrls: params[0].rpcUrls,
	          blockExplorerUrls: params[0].blockExplorerUrls,
	        },
	      ],
	    });
	}catch (addError) {
		console.log(addError)
	}
}

//检查是否授权
async function checkApproved(){

	let signer = store.state.provider.getSigner();

	// 获取授权状态
	let tokenContract = new ethers.Contract(erc20ContractAddress,tokenAbi,signer);
	let address = await signer.getAddress();
	const allowanceAmount = await tokenContract.allowance(address,dbContractAddress);
	const amount  =  Number(ethers.utils.formatUnits(allowanceAmount.toString(), 6));

	return new Promise(async (resolve,reject) => {
		if(Number(ethers.utils.formatUnits(amount, 6)) > 0){
			//已授权
			console.log('已授权');
		    resolve(amount);
		}else{
			//未授权
			console.log('未授权');
			reject("未授权");

		}
	});
}

//mint nft 需要消耗的token数量
async function mintPrice() {

	let signer = store.state.provider.getSigner();

	let mintContract = new ethers.Contract(mintContractAddress, mintContractAbi, signer);

	//获取价格
	let price = await mintContract.getArmsLuckDrawPrice();
	price = Number(ethers.utils.formatUnits(price.toString(), 6));
	console.log(price)
	return price;
	console.log('mint price', price);

}

//简单钱包token余额
async function getBalance() {

	let signer = store.state.provider.getSigner();

	let tokenContract = new ethers.Contract(erc20ContractAddress, tokenAbi, signer);
	let address = await signer.getAddress();
	const balance = await tokenContract.balanceOf(address);
	const amount = Number(ethers.utils.formatUnits(balance.toString(), 6));

	return amount;
	console.log('balance', amount);
}

//授权token，类似初始化账号
async function approve() {

	let signer = store.state.provider.getSigner();

	let tokenContract = new ethers.Contract(erc20ContractAddress, tokenAbi, signer);
	console.log(tokenContract)
	let tokenApprovetxid = await tokenContract.approve(dbContractAddress, ethers.utils.parseUnits('5000000', 6));
	console.log('tokenApprovetxid等待中。。。',tokenApprovetxid);
	let approveRes = await tokenApprovetxid.wait();
	console.log(approveRes,'test')
}

// 交易
async function mintNow(callback) {
	// await login()
	let signer = store.state.provider.getSigner();

	let gasprice = await store.state.provider.getGasPrice();

	 gasprice = gasprice.add(8000000000);

	let mintContract = new ethers.Contract(mintContractAddress, mintContractAbi, signer);

	 let gas = await mintContract.estimateGas.extractCharacter();
	 const gasNum = gas.toNumber();

	 if (gasNum > 1000000) {
	  gas = gas.add('308238');
	 } else {
	  gas = gas.add('100000');
	 }

	//mint nft
	let txid = await mintContract.extractCharacter({
	  gasPrice: gasprice,
	  gasLimit: gas
	 });

	callback(txid);

	let data = await txid.wait();
	console.log(data)
	return data;
}

//获取我的列表
let onmintlist = false;
async function mintList(c) {
	if (onmintlist) {
		return;
	}
	onmintlist = true;

	//https://nft.oracleq.ai/api/nfts
	let signer = store.state.provider.getSigner();

	//我的地址
	let address = await signer.getAddress();
	const res = await axios.post('https://nft.oracleq.ai/api/nfts2', {
		address: address,
		token_addresses: nftAddress,
		chain: "bsc testnet",
		limit: 8,
		cursor: c, //这个是翻页用的
	});

	console.log(res)

	var metadata = res.data.data.result;

	var tr = res.data.data.total;
	var show = res.data.data.page_size;
	var np = res.data.data.page + 1;
	var tp = Math.ceil(tr / show);



	onmintlist = false;

	if (np == tp) {
		return {data:metadata,cursor:null};
	}else{
		return {data:metadata,cursor:res.data.data.cursor};
	}

}

//获取新mint的数据
async function tokenURI(id){
	let signer = store.state.provider.getSigner();

	let nftContract = new ethers.Contract(nftAddress, nftAbi, signer);
	let res = await nftContract.tokenURI(id);
	// 请求token_uri
	let data = await axios.get(res);
	return data.data;
}

//获取交易信息
async function getOrderState(hash,now=()=>{},callback=()=>{}){
	let time = Date.parse(new Date ());
	now(time);
	let res = await store.state.provider.getTransactionReceipt(hash);
	callback(res,time)
	return res;
}

// 重新获取Provider对象
async function regainProvider(){
	var provider = new ethers.providers.Web3Provider(store.state.instance);
	console.log(provider)
	store.commit("setProvider",provider);
}

async function listening(){

	// 监听账号切换
	store.state.provider.provider.on("accountsChanged", (accounts) => {
	  regainProvider()
	});

	// 监听切换网络
	store.state.provider.provider.on("chainChanged", async (chainId) => {
		store.state.chainId = chainId;
	    regainProvider()

		await setNextTime()
		console.log('setNextTime')
	});

	// 监听连接账号
	store.state.provider.provider.on("connect", (info) => {
	  regainProvider()
	});

	// 断开连接监听
	store.state.provider.provider.on("disconnect", (error,message) => {
		regainProvider()
		// 彻底断开
		// logoutOfWeb3Modal()
	});
}

async function getBalanceNum(){
	let signer = store.state.provider.getSigner();
	//我的地址
	let address = await signer.getAddress();
	let res = await store.state.provider.getBalance(address)
	let num = Number(ethers.utils.formatEther(res))

	return num
}

async function setNextTime(){
	let nextTime = await getTokensNextTime()
	store.commit('setNextTime',nextTime)
}

async function getTokens(){

	await addNetwork()
	let state = await checkNetwork()
	if(!state){
		return;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum)
	const signer = provider.getSigner();

	let nftContract = new ethers.Contract(TokenFaucetAddress, TokenFaucetAbi, signer);
	let tx = await nftContract.claim(tokensAddress)

	await checkState(tx.hash)

}

async function getTokensNextTime(){

	let netWorkState = await checkNetwork()
	if(!netWorkState){  return ; }
	const provider = new ethers.providers.Web3Provider(window.ethereum)
	const signer = provider.getSigner();

	try {
		let nftContract = new ethers.Contract(TokenFaucetAddress, TokenFaucetAbi, signer);

		let tx = await nftContract.getNextClaimTime(tokensAddress)
		let time = Number(tx['getTime'].toString())
		let nextTime = moment(time*1000).format('YYYY-MM-DD HH:mm:ss');
		return nextTime;

	}catch (e) {
		return '';
	}

}

export async function checkState(hash){
	let provider = new ethers.providers.Web3Provider(window.ethereum)

	const contract = new ethers.Contract(tokensAddress, erc20ContractAbi, provider);

	store.commit("setGiveMeTokenLoading", true)
	let event = contract.on("Transfer", async (from,to,amount,receipt) => {
		if(receipt.transactionHash == hash){

			let res = await getOrderState(hash)

			if(res){
				if(res.status == 1){
					Notification({
						title: '成功',
						message: '代币获取成功！',
						type: 'success'
					})
				}
				else if(res.status == 0){
					Notification.error({
						title: '错误',
						message: '代币获取失败！'
					});
				}
			}

			store.commit("setGiveMeTokenLoading", false)
			await setNextTime()
			event = null
		}
	});
}

export {
	connection,
	logoutOfWeb3Modal,
	accountsChanged,
	getCurrentAccount,
	checkConnection,
	checkNetwork,
	approve,
	mintNow,
	mintList,
	tokenURI,
	getOrderState,
	getBalance,
	addNetwork,
	checkApproved,
	mintPrice,
	listening,
	regainProvider,
	getCachedProvider,
	getBalanceNum,
	getTokensNextTime,
	getTokens
}
