<template>
	<div class="comments">
		<comment :list="list1"></comment>
		<comment :list="list2":direction="true"></comment>
		<comment :list="list3"></comment>
	</div>
</template>

<script>
	import comment from "./Comment.vue";
	import commentsData from "@/config/commentsData.js";
	export default{
		components:{comment},
		data(){
			return{
				list1:[],
				list2:[],
				list3:[],
			}
		},
		mounted() {
			// 打乱数组并且分配数据
			let arr = commentsData;
			for (let i = 0; i < arr.length; i++) {
			        let iRand = parseInt(arr.length * Math.random());
			        let temp = arr[i];
			        arr[i] = arr[iRand];
			        arr[iRand] = temp;
			}
			this.list1 = arr.slice(0,12);
			this.list2 = arr.slice(12,24);
			this.list3 = arr.slice(24,36);
		},
		methods:{
			
		}
	}
</script>

<style scoped="scoped">
	.comments{
		background-color: #212428;
		height: 300px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
	}
</style>
