<template>
	<div style="padding-top: 100px;background-color: rgba(0,0,0,0);height: 1000px;">
		<button @click="connection()">连接</button>
		<button @click="getCurrentAccount()">获取当前账号</button>
		<button @click="checkConnection()">检查连接</button>
		<button @click="checkNetwork()">检查网络</button>
		<button @click="approve()">授权</button>
		<button @click="logoutOfWeb3Modal()">Web3Modal断开连接</button>
		<button @click="tokenURI()">tokenURI</button>
		<hr />
		当前地址:{{addr}}
		<hr />
		<button @click="getBalanceNum()">getBalanceNum</button>
	</div>
</template>

<script>
	import {
		connection,
		logoutOfWeb3Modal,
		accountsChanged,
		getCurrentAccount,
		checkConnection,
		checkNetwork,
		approve,
		checkApproved,
		mintNow,
		mintList,
		tokenURI,
		getOrderState,
		getBalance,
		addNetwork,
		mintPrice,
		getBalanceNum,
	} from "@/tools/ethers/newIndex.js";
	import {ethers} from "ethers";
	export default{
		name:"test",
		data(){
			return{
				hash:'',
				addr:''
			}
		},
		mounted() {
			console.log(ethers)
		},
		methods:{
			connection(){connection()},
			
			getCurrentAccount(){getCurrentAccount().then(res=>{
				console.log("getCurrentAccount",res)
				this.addr = res[0];
			})},
			
			logoutOfWeb3Modal(){logoutOfWeb3Modal()},
			
			checkConnection(){checkConnection().then(res=>console.log("checkConnection",res))},
			
			checkNetwork(){console.log("checkNetwork",checkNetwork())},
			
			approve(){approve()},
			
			checkApproved(){checkApproved().then(res=>console.log("checkApproved",res))},
			
			accountsChanged(){accountsChanged()},
			
			async getBalanceNum(){
				console.log( + await getBalanceNum(this.addr))
			},
			async tokenURI(){
				console.log(await tokenURI(407))
			}
		}
	}
</script>

<style scoped="scoped">
	button{
		padding: 20px;
		margin: 0px 10px;
		border-radius: 10px;
	}
</style>
<style>

</style>