<template>
	<div class="collection-warp">
		<div class="collection animate__animated  animate__fadeIn" v-if="attributes!=null">
			<el-row>
				<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="hreo"><img :src="attributes[0].imgUrl" /></el-col>
				<el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" class="info">
					<!-- Title -->
					<el-row class="coll-title">
						Hero #{{data.token_id}}
					</el-row>
					
					<!-- About-Start -->
					
					<el-row>
						<div class="list-title">About</div>
					</el-row>
					
					<el-row :gutter="10">
						<el-col :span="8" :xs="24">
							<div class="item">
								<div class="item-title">Level</div>
								<div class="item-value">{{property.level}}</div>
							</div>
						</el-col>
						
						<el-col :span="8" :xs="24">
							<div class="item">
								<div class="item-title">Rarity</div>
								<div class="item-value">{{property.rarity}}</div>
							</div>
						</el-col>
						
						<el-col :span="8" :xs="24">
							<div class="item">
								<div class="item-title">Class</div>
								<div class="item-value">{{property.class}}</div>
							</div>
						</el-col>
					</el-row>
					
					<el-row :gutter="10" class="row">
						<el-col :span="10" :xs="24">
							<div class="item">
								<div class="item-title">Rank</div>
								<div class="item-value">
									
								<el-rate 
									disabled
									:colors="['rgb(255, 238, 119)','rgb(255, 238, 119)','rgb(255, 238, 119)']"
									disabled-void-color="rgba(255,255,255,0.2)" 
									v-model="property.rank">
								</el-rate>
								
								</div>
							</div>
						</el-col>
						<el-col :span="14" :xs="24">
							<div class="item">
								<div class="item-title">Owner</div>
								<div class="item-value">{{property.owner | token}}</div>
							</div>
						</el-col>
					</el-row>
					
					<!-- Status-Start -->
					
					<el-row>
						<div class="list-title">Status</div>
					</el-row>
					
					<el-row>
						<el-col :span="24">
							<div class="item">
								<div class="item-title">Growth</div>
								<div class="growth">
									<div class="growth-value" :style="{width:`${property.growth / 15 * 100}%`}"></div>
								</div>
								<div class="growth-num"> {{property.growth}} / 15 </div>
							</div>
						</el-col>
					</el-row>
					
					<el-row class="row" :gutter="10" >
						<el-col :span="6"  :xs="24">
							<div class="item">
								<div class="item-title">Strength</div>
								<div class="item-value">{{property.strength}}</div>
							</div>
						</el-col>
						<el-col :span="6"  :xs="24">
							<div class="item">
								<div class="item-title">Vitality</div>
								<div class="item-value">{{property.vitality}}</div>
							</div>
						</el-col>
						<el-col :span="6"  :xs="24">
							<div class="item">
								<div class="item-title">Dexterity</div>
								<div class="item-value">{{property.dexterity}}</div>
							</div>
						</el-col>
						<el-col :span="6"  :xs="24">
							<div class="item">
								<div class="item-title">Intelligence</div>
								<div class="item-value">{{property.intelligence}}</div>
							</div>
						</el-col>
					</el-row>
					
					<!-- Equipments & Skills -->
					<el-row :gutter="30">
						<el-col :span="14"  :xs="24">
							<!-- Equipments -->
							<el-row>
								<div class="list-title">Equipments</div>
							</el-row>
							<el-row class="item">
								<div class="item-title">Weapon</div>
									<img :src="attributes[1].imgUrl">
							</el-row>
							<el-row class="item row">
									<div class="item-title">Sub Weapon</div>
									<img :src="attributes[2].imgUrl">
							</el-row>
							<el-row :gutter="10" class="row">
								<el-col :span="12">
									<div class="item">
										<div class="item-title">Armor</div>
										<img :src="attributes[3].imgUrl">
									</div>
								</el-col>
								<el-col :span="12">
									<div class="item">
										<div class="item-title">Gem</div>
										<img :src="attributes[3].imgUrl">
									</div>
								</el-col>
							</el-row>
		
						</el-col>
						
						<el-col :span="10" :xs="24">
							<!-- Skills -->
							<el-row>
								<div class="list-title">Skills</div>
							</el-row>
							
							<el-row class="item">
								<div style="font-size: 38px;color: rgb(114, 114, 114);"><i class="el-icon-document"></i></div>
								<div class="item-value">This character currently does not have any skills yet</div>
							</el-row>
						</el-col>
					</el-row>
				</el-col>
			</el-row>

			<div v-if="attributes==null" class="empty">Temporarily no data!</div>
		</div>
	</div>
</template>

<script>
	import info from "@/config/imageInfo.js";
	export default{
		data(){
			return{
				attributes:null,
				data:"",
				property:{
					level:0,
					rarity:'Normal',
					class:'Soldier',
					rank:3,
					owner:'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
					growth:13,
					strength:25,
					vitality:19,
					dexterity:30,
					intelligence:49
				},
			}
		},
		mounted() {
			this.data = JSON.parse(localStorage.getItem("item"));
			if(this.data.metadata){
				this.attributes = this.data.metadata.attributes;
				this.setData(this.data)
				// console.log(this.data)
			}
		},
		methods:{
			getRandom(max){
				return Math.round(Math.random()*max);
			},
			setData(data){
				let metadata = data.metadata;
				this.property.level = metadata.subLevel;
				this.property.rarity = info[5-Number(metadata.rarity)].name;
				this.property.owner = data.owner_of;
				this.property.rank = Number(metadata.rarity);
				this.property.growth = this.getRandom(15);
				this.property.strength = this.getRandom(100);
				this.property.vitality = this.getRandom(100);
				this.property.dexterity = this.getRandom(100);
				this.property.intelligence = this.getRandom(100);
			}
		}
	}
</script>

<style scoped="scoped">
	.collection-warp{
		height: 100%;
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) 0% 0% / cover,
					url("../assets/images/collection_background.png") no-repeat center center / cover;
	}
	
	.collection-warp::before{
		content: '';
		display: block;
		width: 100%;
		height: 88px;
		background-color: #212428;
	}
	.collection{
		max-width: 1296px;
		min-height:600px;
		margin: 0px auto;
		padding: 80px 20px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;
	}

	.hreo,.info{
		box-sizing: border-box;
		padding: 20px;
	}
	
	/* .row{margin-top: 10px;} */
	.list-title{
		font-size: 18px;
		font-weight: 800;
		color: #f0f0f0;
		text-align: left;
		padding: 10px 0px;
	}
	.coll-title{
		font-size: 38px;
		text-align: left;
		color: #fff;
		font-weight: 600;
		padding: 5px 0px 30px 0px;
	}
	.item{
		    border: solid 1px rgba(255, 255, 255, .15);
			padding: 10px;
			box-sizing: border-box;
			border-radius: 5px;
			cursor: pointer;
			background-color: rgba(0,0,0,0.2);
			margin-bottom: 10px;
	}
	.item:hover{
		background: rgba(131, 100, 226,0.1);
	}
	
	.item-title{
		font-size: 16px;
		font-weight: 600;
		color: #f0f0f0;
	}
	.item-value{
		color: #8364E2;
		font-size: 14px;
		font-weight: 5600;
	}
	
	.growth{
		margin-top: 10px;
		width: 100%;
		height: 10px;
		background-color: rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		overflow: hidden;
	}
	.growth-value{
		transition: all .28s ease-in .1s;
		background-color: rgb(64, 63, 131);
		width: 0%;
		height: 100%;
	}
	.growth-num{
		color: rgb(162, 162, 162);
		font-size: 16px;
		font-weight: 700;
		padding: 20px 0px 10px 0px;
	}

	/* 数据为空的提示样式 */
	.empty{
		padding-top:80px;
		font-size:29px;
		font-weight:600;
		color:#fff;
	}
	
	img{
		width: 100%;
		cursor: pointer;
	}
	.item img{
		width: calc(100% - 20px);
		margin-top: 10px;
	}
	
	/* 响应式代码 */
	@media screen and (max-width: 768px) {
		.collection{
			display: block;
		}
		.sub{
			margin-left: 0%;
		}
		.image,.sub{
			width: 100%;
			box-sizing: border-box;
			padding: 0px 30px;
		}
	}
</style>