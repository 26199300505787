<template>
	<div>
		<!-- 交易框 -->
		<PopUp ref="mint" :confirm="`${pay} USDT`" :callback="confirm" :loading="loading" @close="close">
			<div>Current USDT in your account: {{num}}.</div>
			<div>Pay {{pay}} USDT to mint a Hero?</div>
		</PopUp>

		<!-- 确认授权 -->
		<PopUp ref="approve" confirm="Approve" :callback="ApprovedMint" :loading="approveLoading" @close="close">
			<div style="line-height: 18px;">
				In order to continue transaction, you need to grant our smart contract permission to automatically transfer USDT from your wallet
			</div>
		</PopUp>

		<!-- 消息 -->
		<PopUp ref="message" :confirm="false" :callback="()=>{}">
			<div style="line-height: 18px;">
				<div style="font-size: 18px; color: #d34f4f; font-weight: 800;">Failed!</div>
				<div style="margin-top: 5px;margin-right: 5px;">There was an error in Minting. Please try again</div>
			</div>
		</PopUp>

		<!-- 余额不足 -->
		<PopUp ref="enough" :confirm="false" :callback="()=>{}">
			<div style="line-height: 18px;">
				<div>USDT Not enough</div>
			</div>
		</PopUp>

		<!-- 余额不足 -->
		<PopUp ref="ethEnough" :confirm="false" :callback="()=>{}">
			<div style="line-height: 18px;">
				<div>Approving Failure , ETH Not enough</div>
			</div>
		</PopUp>
	</div>
</template>

<script>
	import PopUp from "@/components/PopUp.vue";
	import minta from '@/tools/ethers/mint.js';
	import {
		mintPrice,
		getBalance,
		checkApproved,
		accountsChange,
		checkConnection,
		connection,
		checkNetwork,
		addNetwork,
		regainProvider,
		getCachedProvider,
		approve,
		getBalanceNum
	} from '@/tools/ethers/newIndex.js';
	import {
		mapState,
		mapMutations
	} from 'vuex';
	import Vue from 'vue';
	export default {
		components:{PopUp},
		props:{
			open:{
				default:false,
				type:Boolean
			},
		},
		data() {
			return {
				num: 0,
				pay: 1,
				loading: false,
				approveLoading:false,
				message: '',
			}
		},
		methods: {
			async start() {
				// 检查是否连接
				let count = 0;

				await connection((res,icon)=>{
					// 登入后修改数据
					let filter = Vue.filter('token');
					let token = filter(res[0])
					this.setRealToken(res[0])
					this.setToken(token);
					checkNetwork().then(async res=>{
						if(res){
							getBalance().then(res=>this.setXyzNum(res));
							let balanceNum = await getBalanceNum();
							this.setEthNum(balanceNum)

							if(!getCachedProvider()){
								return;
							}else{
								let tmp = await checkNetwork();
								console.log(tmp)
								if(tmp){
									setTimeout(()=>{
										this.startAfter();
									},1000)
								}
							}
						}
					});
					if(icon){
						this.setIcon(icon);
					}
				})
			},
			async startAfter(){
				// 检查是否授权
					checkApproved().then(res=>{
						let tmp = 0;
						mintPrice().then((res => {
							this.pay = res;
							tmp++;

							if(tmp>=2){
								this.$refs.mint.open();
								if(this.num<this.pay){
									this.$refs.enough.open();
									this.$refs.mint.hidden();
									return;
								}
							}
						}))
						getBalance().then((res => {
              console.log(res)
							this.num = res;
							tmp++;

							if(tmp>=2){
								this.$refs.mint.open();
								if(this.num<this.pay){
									this.$refs.enough.open();
									this.$refs.mint.hidden();
									return;
								}
							}
						}))
					}).catch(async ()=>{
						let balanceNum = await getBalanceNum();
						if(balanceNum>=0.00029){
							this.$refs.approve.open();
						}else{
							this.$refs.ethEnough.open();
						}
					})

			},
			confirm() {
				this.mint();
			},

			// 授权后Mint
			async ApprovedMint(){
				if(this.approveLoading) return false;
				this.approveLoading = true;
				approve().then(res=>{
					console.log(res)
					this.approveLoading = false;
					this.$refs.approve.hidden();
					// this.mint();
				}).catch(res=>{
					this.approveLoading = false;
					this.showMessage();
					this.$refs.approve.hidden();
				})

				if(this.icon.indexOf('walletconnect')!=-1){
					this.approveLoading = false;
					this.$refs.approve.hidden();
				}
			},
			async mint() {

				this.$refs.approve.hidden();
				if(!this.loading){
					this.loading = true;

						await minta(
							//执行中
							res => {
								this.loading = false;
								this.$refs.mint.hidden();
								setTimeout(()=>{
									this.$router.push({
										name: 'collection',
										params: {
											successpay: 'ok'
										},
										// query:{
										// 	time:Date.parse(new Date())
										// }
									})
								},200)
							},
							// 失败
							(res) => {
								this.loading = false;
								this.$refs.mint.hidden();
								// this.showMessage(res.message);
							},
							// 成功
							(res) => {
								console.log(res)
								this.loading = false;
								this.setNewData(res);
							},
						)

				}
			},
			showMessage(message="") {
				this.$refs.message.open();
			},
			close(){
				this.loading = false;
				this.approveLoading = false;
			},

			goawaitt(){
				let dom = document.createElement("div");
				dom.style.opacity = "1";
				dom.style.position = "fixed";
				dom.style.right = "calc(50vw - 30px)";
				dom.style.bottom = "calc(100vh - 200px)";
				dom.style.width = "60px";
				dom.style.height = "60px";
				dom.style.zIndex = "9";
				dom.style.borderRadius = "30px";
				dom.style.background = "rgb(131, 100, 226)";
				dom.style.transition = "all 1s";
				document.body.append(dom)
				setTimeout(()=>{
					dom.style.right = "50px";
					dom.style.bottom = "50px";
					dom.style.transform = "scale(0.5)";
					dom.style.opacity = "0";
					dom.addEventListener('transitionend',e=>{
						dom.remove();
					})
				},500);
			},
			...mapMutations(['setNewData','setToken','setXyzNum','setIcon','setRealToken','setEthNum'])
		},
		computed:{
			...mapState(['token','provider','icon']),
		}
	}
</script>

<style>
</style>
