import { render, staticRenderFns } from "./RankingView.vue?vue&type=template&id=2a6ae174&scoped=true&"
import script from "./RankingView.vue?vue&type=script&lang=js&"
export * from "./RankingView.vue?vue&type=script&lang=js&"
import style0 from "./RankingView.vue?vue&type=style&index=0&id=2a6ae174&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6ae174",
  null
  
)

export default component.exports