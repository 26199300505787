<template>
	<div class="ranking-warp">
		<page-top-title>
			<span slot="title">Grand Prix Rank</span>
		</page-top-title>
		<div class="ranking-box animate__animated animate__fadeIn">
			<div class="table">
				<div class="table-header row">
					<div class="col" col="1">Ranking</div>
					<div class="col" col="2">ID</div>
					<div class="col" col="3">Point</div>
					<div class="col" col="4">Reward</div>
				</div>
				<div class="row" v-for="(item,idx) in tableData" v-show="idx+1>=(tableIndex*25-24)&&idx+1<=tableIndex*25">
					<div class="col" col="1">
						<div class="top-icon">
							<img v-if="idx+1<=3" :src="getImgAddress(idx+1)" />
						</div>
						{{idx+1}}
					</div>
					<div class="col" col="2">{{item.id}}</div>
					<div class="col" col="3">{{item.point}}</div>
					<div class="col" col="4">{{item.reward}}</div>
				</div>
			</div>
			
			<div class="button-box animate__animated animate__fadeIn">
				<button :class="{onButton:tableIndex==1}" @click="tableIndex=1">1-25</button>
				<button :class="{onButton:tableIndex==2}" @click="tableIndex=2">26-50</button>
				<button :class="{onButton:tableIndex==3}" @click="tableIndex=3">51-75</button>
				<button :class="{onButton:tableIndex==4}" @click="tableIndex=4">76-100</button>
			</div>
		</div>
	</div>
</template>

<script>
	import RankingData from "@/config/RankingData.js";
	import PageTopTitle from "@/components/PageTopTitle.vue";
	export default{
		components:{PageTopTitle},
		data(){
			return{
				tableIndex:1,
				tableData:RankingData
			}
		},
		mounted() {
		},
		methods:{
			getImgAddress(src){
				return ''+require("@/assets/images/ranking/"+src+".webp");
			}
		},
		computed:{

		}
	}
</script>

<style scoped="scoped">
	.ranking-warp{
		background: url("../assets/images/collection_background.png") no-repeat center center / cover;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.ranking-warp>div{
		width: 100%;
	}
	.ranking-warp::before{
		content: '';
		display: block;
		width: 100%;
		height: 88px;
		background-color: #212428;
	}
	.ranking-box{
		max-width: 1296px;
		width: 100%;
		min-height: 800px;
		box-sizing: border-box;
		padding: 0px 20px;
		overflow: auto;
	}
	.row{
		display: flex;
		color: #fff;
		height: 16px;
		padding: 20px 0px 20px 0px;
		text-align: center;
		border-bottom: 1px solid #47494f;
	}
	.table-header{
		font-weight: 500;
	}
	.col[col="1"]{
		position: relative;
		min-width: 300px;
		box-sizing: border-box;
		padding-left: 50px;
		text-align: left;
	}
	.col[col="2"]{
		min-width: 500px;
	}
	.col[col="3"]{
		min-width: 300px;
	}
	.col[col="4"]{
		/* min-width: 300px; */
	}
	.col .top-icon{
		position: absolute;
		height: 100%;
		left: 10px;
		top: 0px;
	}
	.col .top-icon img{
		height: 100%;
		transform: scale(1.8);
	}
	.button-box{
		height: 50px;
		margin: 30px 0px;
	}
	.button-box button{
		height: 100%;
		padding: 0px 20px;
		border: 1px solid rgba(255, 255, 255, .3);
		background-color: rgba(0,0,0,0);
		color: #fff;
		cursor: pointer;
	}
	.button-box button:not(:last-child){
		border-right-width: 0px;
	}
	.onButton{
		background-color: #8364E2 !important;
		border-color: #8364E2 !important;
	}
	
	
	@media screen and (max-width: 1300px) {
		.col[col="1"],.col[col="2"]{
			min-width: 250px;
		}
	}
	
	@media screen and (max-width: 950px) {
		.col[col="1"],.col[col="2"]{
			min-width: 180px;
		}
	}
	@media screen and (max-width: 770px) {
		.col[col="3"],.col[col="4"]{
			display: none;
		}
		.col[col="1"],.col[col="2"]{
			min-width: 50%;
		}
	}
</style>
