export default 	[
    "The graphic is fantastic 😍",
    "The models look great!",
    "Fantastic! Thank you",
    "An excellent game.",
    "Amazing ❤️",
    "Super addicted",
    "Music is perfect 👌🏻",
    "The game Excited, so cool 😎",
    "Unique Amazing 🤩",
    "The game is so smooth",
    "GOOD GAME!!!",
    "Very nice graphic",
    "Nice game",
    "The BEST game I've ever seen",
    "Amazing graphic",
    "REAAAALLY GOOD!",
    "Amazing P2E Game",
    "Music is gorgeous",
    "Breathtaking skills",
    "Better than most games",
    "One of the best NFT games 🥳",
    "Amazing!",
    "Awesome story graphic",
    "Many players will love this.",
    "Brings back to childhood",
    "It all looks very cool!",
    "the game is awesome",
    "There are lots of details",
    "this's a really cool game",
    "graphics smooth as butter",
    "Story is interesting af",
    "OMG LIT!",
    "AWESOME!!!",
    "I like it!",
    "Cool done project",
    "The game dragged me on",
    "Game that many will love",
    "The icon set got me =)",
    "It's DOPE y'all"
]