<template>
	<div class="faq-warp">
		<div class="height">How To Get TBNB</div>
		<div class="faq-box">
			<div class="tips steps">
				<div class="step-title">Step 1</div>
				<div class="no1">
					Go to <a href="https://testnet.binance.org/faucet-smart" target="_blank">https://testnet.binance.org/faucet-smart.</a>
				</div>
			</div>

			<div class="steps">
				<div class="step-title">Step 2</div>
			  <div class="step-img">
				  <el-image style="width: 100%;border-radius: 5px;" :src="list[0]" :preview-src-list="list"></el-image>
			  </div>
			</div>

			<div class="steps">
				<div class="step-title">Step 3</div>
				<div class="step-img">
					 <el-image style="width: 100%;border-radius: 5px;" :src="list[1]" :preview-src-list="list"></el-image>
				</div>
			</div>

      <div class="steps">
        <div class="step-title">Step 4</div>
        <div class="no1">
            <GetTokens tokensName="USDT"></GetTokens>
        </div>
      </div>
		</div>
	</div>
</template>

<script>
  import GetTokens from "@/components/GetTokens";

  export default{
    components:{GetTokens},
		data(){
			return{
				list:[
					''+require("@/assets/images/step2.png"),
					''+require("@/assets/images/step3.png"),
				]
			}
		}
	}
</script>

<style scoped="scoped">
	.faq-warp{
		background: url("../assets/images/collection_background.png") no-repeat center center / cover;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0px 20px;
	}
	.faq-warp::before{
		content: '';
		display: block;
		width: 100%;
		height: 88px;
		background-color: #212428;
	}
	.faq-box{
		max-width: 1296px;
		width: 100%;
		min-height: 800px;
		padding: 20px 40px;
		box-sizing: border-box;
		overflow: auto;
		border-radius: 0px 0px 10px 10px;
		background-color: rgb(33, 36, 40);
		color: #ebebeb;
		margin-bottom: 40px;
	}
	.steps{
		box-sizing: border-box;
		padding: 10px;
	}
	.steps img{
		width: 100%;
		border-radius: 5px;
	}
	.step-title{
		font-size: 21px;
		text-align: left;
		margin-bottom: 10px;
	}
	.no1{
		box-sizing: border-box;
		text-align: left;
		font-size: 18px;
		padding: 10px;
		width: 100%;
		word-wrap: break-word;
	}
	.no1 a{
			color: rgb(172, 159, 226);
	}
	.step-img{
		box-sizing: border-box;
		padding: 10px;
	}
	.height{
		max-width: 1296px;
		text-align: left;
		width: 100%;
		height: 60px;
		box-sizing: border-box;
		padding: 20px 40px;
		margin-top: 40px;
		border-radius: 10px 10px 0px 0px;
		background-color: rgba(255, 255, 255, 0.3);
		color: #fff;
	}

	@media screen and (max-width: 768px) {
		.faq-box{
			padding: 0px;
		}
	}
</style>
