import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: '',	//导航栏显示的token
		newData: {},//新数据的存放用于更新Collection页面的
		orderList: [],//交易列表等待加载
		web3Modal: null,//web3Modal全局加载
		provider: null,//web3Modal全局加载
		instance: null,//web3Modal全局加载
		chainId:null,//chainId
		xyzNum:0,	//余额
		icon:'', //图标
		realToken:'',
		ethNum:0,
		nextTime:'',
		giveMeTokenLoading:false
	},
	getters: {},
	mutations: {
		setToken(state, val) {
			state.token = val;
		},
		setNewData(state, val) {
			state.newData = val;
		},
		setOrderList(state, val) {
			state.orderList = val;
		},
		setWeb3Modal(state, val) {
		 state.web3Modal = val;
		},
		setProvider(state, val) {
			state.provider = val;
		},
		setInstancel(state, val) {
			state.instance = val;
		},
		setChainId(state, val) {
			state.chainId = val;
		},
		setXyzNum(state, val) {
			state.xyzNum = val;
		},
		setIcon(state, val) {
			state.icon = val;
		},
		setRealToken(state, val){
			state.realToken = val;
		},
		setEthNum(state, val){
			state.ethNum = val;
		},
		setNextTime(state, val){
			state.nextTime = val;
		},
		setGiveMeTokenLoading(state, val){
			state.giveMeTokenLoading = val;
		}
	},
	actions: {},
	modules: {},
});
