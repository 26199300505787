<template>
	<div class="content-title wow animate__animated  animate__fadeInUp">
		<h2>{{value}}</h2>
	</div>
</template>

<script>
	export default{
		props:{
			value:{
				default:''
			}
		}
	}
</script>

<style scoped="scoped">
	.content-title{
		margin-bottom: 20px;
		height: 48px;
	}
	h2{
		font-size: 30px;
		color: #fff;
		text-align: left;
	}
</style>
