
import tokenAddress from "./bscTestNet/ERC20Token.erc20TokenAddress.json";
import LogicCharacter from "./bscTestNet/LogicCharacter.logicCharacter.json";
import LogicStorage from "./bscTestNet/LogicStorage.logicStorageAddress.json";
import characterAddress from "./bscTestNet/Nft.characterAddress.json";
import TokenFaucet from "./bscTestNet/tokenFaucet.json";
import TetherToken from "./bscTestNet/TetherToken.json"

export default {
	erc20ContractAddress:tokenAddress.address,
	erc20ContractAbi:tokenAddress.abi,
	dbContractAddress:LogicStorage.address,
	nftAddress:characterAddress.address,
	mintContractAddress:LogicCharacter.address,
	tokenAbi:tokenAddress.abi,
	mintContractAbi:LogicCharacter.abi,
	nftAbi:characterAddress.abi,
	TokenFaucetAddress:TokenFaucet.address,
	TokenFaucetAbi:TokenFaucet.abi,
	TetherTokenAddress:TetherToken.address
};
