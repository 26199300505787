export default[
	{
		"name":"Legendary",
		"num":10,
		"rarity":"2%",
		"file":"legendary"
	},
	{
		"name":"Epic",
		"num":15,
		"rarity":"5%",
		"file":"epic"
	},
	{
		"name":"Super Rare",
		"num":20,
		"rarity":"8%",
		"file":"sr"
	},
	{
		"name":"Rare",
		"num":25,
		"rarity":"13%",
		"file":"rare"
	},
	{
		"name":"Normal",
		"num":25,
		"rarity":"70%",
		"file":"normal"
	},
]