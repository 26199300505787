import {mintNow} from '@/tools/ethers/newIndex.js';
import store from '@/store/index.js';

export default async function mint(run,carch=()=>{},then=()=>{}){
	await mintNow((res)=>{
		let tmp = [...store.state.orderList];
		console.log(tmp)
		tmp.push(res.hash);
		store.commit("setOrderList",tmp);
		run(res);
	}).catch((res)=>{
		console.log(res)
		carch(res);
	}).then((res)=>{
		if(!res) return;
		for(let item of res.events){
		 if(item.event == "CharacterExtract"){
			let tmp = item.args;
			console.log("CharacterExtract",res)
			let addr = `${tmp.addr.substring(0, 6)}...${tmp.addr.substring(tmp.addr.length - 4)}`;
			let data = ({
				addr:addr,
				owner_of:tmp.addr,
				quality:tmp.quality.toNumber(),
				subLevel:tmp.subLevel.toNumber(),
				armor:tmp.armor.toNumber(),
				gem:tmp.gem.toNumber(),
				weapon:tmp.weapon.toNumber(),
				subWeapon:tmp.subWeapon.toNumber(),
				tokenid:tmp.tokenid.toNumber(),
				blockNumber:res.blockNumber,
			})
			then(data)
		 }
		}
	})
}

