<template>
	<div class="rarity">
		<div class="top wow animate__animated animate__fadeIn">
			<div class="name">{{info.name}}</div>
			<div class="rarityNum">{{info.rarity}}</div>
			<img :src="getImgAddress('title')" />
		</div>
		<div class="content wow animate__animated animate__fadeIn">
			<div class="content-img wow animate__animated animate__fadeIn" v-for="item in value">
				 <el-image 
				    :src="item"
					class="wow animate__animated animate__fadeIn"
					style="border-radius: 10px;"
				    :preview-src-list="value">
				  </el-image>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			info:{
				default:{}
			}
		},
		data(){
			return{
				value:[]
			}
		},
		mounted() {
			this.randArr();
		},
		methods:{
			getImgAddress(src){
				return ''+require('../../assets/images/rarity/'+this.info.file+"/"+src+".png");
			},
			randArr(){
				// 创建数组添加数据
				let arr = [];
				
				for(let idx = 1;idx<=this.info['num'];idx++){
					arr.push(idx);
				}
				
				// 打乱数据
				for (let i = 0; i < arr.length; i++) {
				        let iRand = parseInt(arr.length * Math.random());
				        let temp = arr[i];
				        arr[i] = arr[iRand];
				        arr[iRand] = temp;
				}
				
				// 获取打乱前4个
				arr = arr.splice(0,4);
				
				// 获取图片路径并且重新赋值
				for(let i = 0; i < arr.length; i++){
					arr[i] = this.getImgAddress(arr[i]);
				}
				
				// 渲染用的变量赋值
				this.value = arr;
			}
		}
	}
</script>

<style scoped="scoped">
	.rarity{
		width: 100%;
		overflow: hidden;
		background-color: rgba(47,51,57,0.9);
		margin-bottom: 40px;
		border-radius: 10px;
		box-shadow: 2px 2px 10px rgba(0,0,0,0.5);
		min-height: 512px;
	}
	
	.top{
		height: 100px;
		padding: 20px 0px;
		text-align: left;
		position: relative;
	}
	
	.top img{
		height: 100%;
	}
	
	.content{
		width: 100%;
		display: flex;
		align-items: center;
	}
	
	.content-img{
		padding: 40px;
		width: 25%;
	}
	.name,
	.rarityNum{
		color: #eaeaea;
		position: absolute;
	}
	.name{
		top: 20px;
		left: 10px;
		font-size: 30px;
		font-weight: 600;
	}
	
	.rarityNum{
		font-size: 20px;
		font-weight: 600;
		bottom: 20px;
		left: 220px;
	}
	
	@media screen and (max-width: 1200px) {
		.content-img{
			padding: 10px;
			width: 25%;
		}
	}
	
	@media screen and (max-width: 860px) {
		
		.content{
			width: 100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
		}
		
		.content-img{
			padding: 10px 40px 10px 40px;
			box-sizing: border-box;
			width: 100%;
		}
		
		.rarity{
			padding-bottom: 30px;
		}
	}
</style>
<style>
	.content-img .el-image img{
		transition: opacity 0.3s;
	}
	.content-img .el-image img:hover{
		opacity: 0.5;
		
	}
	.el-image-viewer__canvas{
		box-sizing: border-box;
		padding: 20px;
	}
</style>