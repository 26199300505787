<template>
	<div class="tournament-warp">

		<div class="top">
			<!-- 标题 -->
			<div class="title wow animate__animated animate__fadeIn">
				<img src="../assets/images/tournament/title.png" />
			</div>

			<!-- 时间 -->
			<div class="timer wow animate__animated animate__fadeIn">
				<div class="timer-box wow animate__animated animate__fadeIn"></div>
				<div class="timer-data">
					<div class="timer-num">
						<span>{{day}}</span>
						<span>:</span>
						<span>{{hours}}</span>
						<span>:</span>
						<span>{{min}}</span>
						<span>:</span>
						<span>{{sec}}</span>
					</div>
				</div>
				<img src="../assets/images/tournament/timer.png" />
			</div>
		</div>

		<div class="dowm wow animate__animated animate__fadeIn">

				<div class="btn1">
					<img src="../assets/images/tournament/btn1.png" />
				</div>

				<div class="btn2">
					<img src="../assets/images/tournament/btn2.png" />
				</div>

				<div class="line"></div>

				<div class="leaderboard-title">
					<img src="../assets/images/tournament/leaderboard_title.png" />
				</div>
				<div class="leaderboard">
					<img src="../assets/images/tournament/leaderboard.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				time:'',
				TargetTime: (new Date().getTime() + (60 * 60 * 24 * 1000 * 10)),
				day:"00",
				hours:"00",
				min:"00",
				sec:"00",
			}
		},
		mounted() {
			this.timer = setInterval(()=>{
				this.setData();
			},900)
		},
		methods:{
			setData(){
				// let d = 
				let now = new Date();
				let TargetTime = new Date(this.TargetTime);
				let diff = (TargetTime - now)/1000;
				this.day = parseInt(diff / 86400);
				this.hours = parseInt(diff / 3600)-24 * this.day;
				this.min =  parseInt(diff % 3600 / 60);
				this.sec = parseInt(diff % 60);
			}
		},
		beforeDestroy() {
			clearInterval(this.timer)
		}
	}
</script>

<style scoped="scoped">
	.tournament-warp{
		padding-top: 88px;
		background: rgba(0,0,0,0);
		background-image: linear-gradient(212deg, rgb(45, 23, 113) 3.22%, rgb(33, 36, 40) 32.86%, rgb(33, 36, 40) 68.14%, rgb(70, 57, 41) 93.54%);
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.top{
		width: 100%;
	}

	.title{
		margin-bottom: 100px;
		margin-top: 40px;
		padding: 0px 20px;
	}
	.title img{
		max-width: 500px;
		width: 100%;
	}
	.timer{
		margin-bottom: 100px;
		position: relative;
	}
	.timer img{
		max-width: 500px;
		width: 80%;
		position: relative;
		z-index: 1;

	}
	.timer-box{
		position: absolute;
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		z-index: 0;
	}
	.timer-box::after{
		content: '';
		height: 2px;
		width: 100%;
		background-image: linear-gradient(269.86deg, rgb(131, 100, 226) 45.28%, rgb(62, 28, 165) 51.13%);
	}
	.timer-data{
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 2;
		display: flex;
		justify-content: center;
	}
	.timer-num{
		max-width: 500px;
		height: 100%;
		width: 80%;
		color: rgb(162, 162, 162);
		font-size: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 10px;
		box-sizing: border-box;
	}
	.timer-num span{
		display: block;
		width: 50px;
	}
	.dowm{
		padding: 0px 20px;
		max-width: 1296px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.dowm>div{
		width: 100%;
	}

	.btn1 img{
		max-width: 800px;
		width: 100%;
	}
	.btn1{
		margin-bottom: 20px;
	}
	.btn2 img{
		max-width: 500px;
		width: 55%;
	}
	.line{
		height: 2px;
		margin: 50px;
		max-width: 1036px;
		background-image: linear-gradient(269.86deg, rgb(131, 100, 226) 45.28%, rgb(62, 28, 165) 51.13%) ;
	}
	.leaderboard-title{
		margin-bottom: 20px;
	}
	.leaderboard-title img{
		max-width: 200px;
		width: 100%;
	}
	.leaderboard img{
		max-width: 800px;
		width: 100%;
	}

	@media screen and (max-width: 550px) {
		.timer-num{
			padding: 10px;
			width: 25%;
			font-size: 36px;
		}
	}
</style>
