
const STORAGENAME = "orderList";



// 获取数据
async function getList(addr) {
	addr = addr.toUpperCase();
	// console.log(addr)
	let JsonData = await localStorage.getItem(STORAGENAME);
	if(JsonData == "undefined" || JsonData == "null" || JsonData == undefined || JsonData == null || JsonData == ""){
		return [];
	}else{
		let list = JSON.parse(JsonData);
		// console.log(list)
		if(list[addr]){
			console.log(1,list[addr])
			return list[addr];
		}else{
			return[];
		}
	}
}


// 存进localStorage
async function setLocalStorage(list,addr){
	addr = addr.toUpperCase();
	let JsonData = await localStorage.getItem(STORAGENAME);
	let newData = null;
	if(JsonData == "undefined" || JsonData == "null" || JsonData == undefined || JsonData == null || JsonData == ""){
		let data = {
			[addr]:list
		}
		newData = JSON.stringify(data);
	}else{
		let tmpData = JSON.parse(JsonData);
		tmpData[addr] = list;
		newData = JSON.stringify(tmpData);
	}
	await localStorage.setItem(STORAGENAME,newData);
}
export {
	getList,
	setLocalStorage
}
