<!-- Collection列表元素组件 -->
<template>
	<div :ref="_uid" style="border-radius: 10px;" :class="{['new-item']:bright==true}">
		<div class="collection-item walletKey animate__animated animate__fadeIn">
			<div class="item-image">
				<img :src="image" class="hover-image" />
			</div>
			
			<div class="item-name">{{title}}</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			img:{
				default:'',
				type:String
			},
			name:{
				default:'',
				type:String
			},
			href:{
				default:'',
				type:String
			},
			image:{
				default:'/images/loading.gif',
				type:String
			},
			title:{
				default:'',
				type:String
			},
			bright:{
				default:false,
				type:Boolean
			}
		},
		data(){
			return{
				tmpBright:false
			}
		},
		mounted() {
			this.$refs[this._uid].addEventListener('animationend', (e)=>{
				this.$emit("animationend",false);
			});

			var newImage = new Image();
			newImage.src = '/images/loading.gif';
			newImage.onload = ()=>{
				newImage.src = this.image;
			}
		},
		watch:{
		}
	}
</script>

<style scoped="scoped">
	.collection-item{
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, .025);
		border-top: solid 1px rgba(255, 255, 255, .1);
		box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
		position: relative;
		border-radius: 10px;
	}
	.item-image img{
		border-radius: 10px;
		width: 80%;
		margin-top: 10%;
		box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 5px 0px;
	}
	.item-name{
		margin-top: 20px;
		color: #fff;
		font-weight: 600;
		padding-bottom: 20px;
	}
	
	/* 响应式代码 */
	@media screen and (max-width: 1200px) {

	}
	
	.hover-image{
		cursor: pointer;
		transition: box-shadow 0.3s;
	}
	.hover-image:hover{
		box-shadow: 0px 0px 15px  rgba(131,100,226,0.9);
	}
	
	/* 新数据加进来的动画 */
	.new-item{
		animation: newitem 2s;
		box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
		-webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
	}
	
	@keyframes newitem {
	  0%{box-shadow: 0px 0px 10px rgba(131, 100, 226,0);}
	  25%{box-shadow: 0px 0px 10px rgba(131, 100, 226,0.9);}
	  50%{box-shadow: 0px 0px 10px rgba(131, 100, 226,0);}
	  75%{box-shadow: 0px 0px 10px rgba(131, 100, 226,0.9);}
	  100%{box-shadow: 0px 0px 10px rgba(131, 100, 226,0);}
	}
</style>
