<template>
	<div class="rarity-warp">
		<page-top-title>
			<span slot="title">Rarity</span>
			<span slot="content">There are 5 different rarities of equipment items in NFT maker. Rarity is determined by what stats an item has, and can be changed by crafting.</span>
		</page-top-title>
		<div class="rarity-box">
			<div v-for="(item,index) in images">
				<Item :info="item"></Item>
			</div>
		</div>
	</div>
</template>

<script>
	import Item from "@/components/Rarity/Item.vue";
	import PageTopTitle from "@/components/PageTopTitle.vue";
	import ImageInfo from "@/config/imageInfo.js";
	
	export default{
		components:{Item,PageTopTitle},
		data(){
			return{
				images:ImageInfo
			}
		},
		mounted() {
		},
		methods:{
			getImage(){
			}
		}
	}
</script>

<style scoped="scoped">
	.rarity-warp{
		background: url("../assets/images/collection_background.png") no-repeat center center / cover;
		min-height: 800px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.rarity-warp::before{
		content: '';
		display: block;
		width: 100%;
		height: 88px;
		background-color: #212428;
	}
	.rarity-box{
		max-width: 1296px;
		width: 100%;
		box-sizing: border-box;
		padding: 0px 20px;
		overflow: auto;
	}
</style>
