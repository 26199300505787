const net = {
	net56: [{
		chainId: '0x38',
		chainName: 'BNB Smart Chain Mainnet',
		nativeCurrency: {
			name: 'BNB',
			symbol: 'BNB',
			decimals: 18
		},
		rpcUrls: ['https://bsc-dataseed1.binance.org/'],
		blockExplorerUrls: ['https://bscscan.com/']
	}],

	net97: [{
		chainId: '0x61',
		chainName: 'BNB Smart Chain Testnet',
		nativeCurrency: {
			name: 'tBNB',
			symbol: 'tBNB',
			decimals: 18
		},
		rpcUrls: ['https://patient-wispy-lake.bsc-testnet.discover.quiknode.pro/bc2c152931aa95fd853dbe4e2849b01266a62d4d/'],
		// rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
		// rpcUrls: ['https://speedy-nodes-nyc.moralis.io/689ca50e3e5cb079fef8d87a/bsc/testnet'],
		blockExplorerUrls: ['https://testnet.bscscan.com/']
	}]
}

export default net;
