<template>
	<div class="popver animate__animated">
		<ul>
			<li>
				<div class="popver-title">Address: </div>
				<el-tooltip popper-class="popver-copy" class="popver-content addr" effect="dark" :content="copyTextNow" :enterable="false" placement="right">
				    <div @click="copy()">{{token}}</div>
				</el-tooltip>
			</li>
			<li>
				<div class="popver-title">Token:</div>
				<el-tooltip popper-class="popver-copy" class="popver-content addr" effect="dark" :content="num+''"  :enterable="false" placement="right">
					<div><span class="c-text">{{num}}</span>  USDT</div>
				</el-tooltip>
			</li>
			<li>
				<div @mouseover="" class="popver-title">Balance:</div>
				<el-tooltip popper-class="popver-copy" class="popver-content addr" effect="dark" :content="ethNum+''" :enterable="false" placement="right">
					<div class="popver-content"><span class="c-text">{{ethNum.toFixed(4)}}</span> tBNB</div>
				</el-tooltip>
			</li>
			<li><button @click="logoutOfWeb3Modal()">Disconnect</button></li>
		</ul>
	</div>
</template>

<script>
	import {logoutOfWeb3Modal} from '@/tools/ethers/newIndex.js';
	import copy from '@/tools/function/copy.js';
	import { mapState } from 'vuex';
	export default{
		props:{
			token:{
				default:''
			},
			num:{
				default:0,
			}
		},
		data(){
			return{
				copyTextNow:'点击可复制',
				copyText:['点击可复制','已复制'],
				copyLock:false,
				timer:null,
			}
		},
		methods:{
			logoutOfWeb3Modal(){logoutOfWeb3Modal()},
			copy(){
				if(this.copyLock){
					this.copyLock = false;
					clearTimeout(this.timer);
				}
				console.log(this.realToken)
				copy(this.realToken);
				this.copyLock = true;
				this.copyTextNow = this.copyText[1];
				this.timer = setTimeout(()=>{
					this.copyLock = false;
					this.copyTextNow = this.copyText[0];
				},2000)
			}
		},
		computed:{
			...mapState(['realToken','ethNum'])
		}
	}
</script>

<style scoped>
	.popver{
		z-index: 9;
		width:100%;
		height: 200px;
		right: 0px;
		top: 70px;
		color: #fff;
		border-radius: 5px;
		text-align: left;
	}
	.popver ul li{
		height: 50px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: #fff;
	}
	.popver ul li:not(:last-child){
		border-bottom: 1px solid #444444;
	}
	.popver ul li>button{
		width: 100%;
		height: 100%;
		border-radius: 5px;
		background-color: rgb(131, 100, 226);
		color: #fff;
		font-weight: 600;
	}
	.popver ul li:last-child{
		padding: 10px 0px 0px 0px;
	}
	.popver-title{
		width: 65px;
		font-weight: 800;
	}
	.popver-content{
		display: flex;
		margin-left: 10px;
		font-weight: 400;
		font-size: 12px;
	}
	.c-text{
		display: block;
		max-width: 90px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow:ellipsis;
		margin-right: 5px;
	}
	.addr{
		cursor: pointer;
	}
</style>
<style>
	.popver-copy{
		box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
	}

</style>
