<template>
  <div id="app">
	<NavList></NavList>
    <router-view />
	<Floating></Floating>
	<Footer></Footer>
	<el-backtop :bottom="120" :right="right"></el-backtop>
  </div>
</template>

<script>
	import NavList from "@/components/NavList.vue";
	import Footer from "@/components/Footer.vue";
	import Floating from "@/components/Floating.vue";
	
	export default{
		components:{NavList,Footer,Floating},
		data(){
			return{
				right:60
			}
		},
		mounted() {
			
			// 修改距离位置
			this.setRightVal();
			window.addEventListener('resize', ()=>{
				this.setRightVal();
			})
		},
		methods:{
			setRightVal(){
				var w = document.documentElement.clientWidth || document.body.clientWidth;
				if(w<=600){
					if(this.right!=10){this.right=20}
				}else{
					if(this.right!=60){this.right=60}
				}
			}
		}
	}
</script>

<style>
/* 清楚默认样式 */
@import url("assets/css/clearDefault.css");
/* 导入animate.css动画库 */
@import url("assets/css/animate.min.css");
/* 字体导入 */
@import url("assets/css/fonts.css");

@import url("tools/message/style.css");

#app {
  font-family: "DM Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
button{
	font-family: "DM Sans", Helvetica, Arial, sans-serif;
}

.hover-button{
	transition: box-shadow 0.3s;
}

.hover-button:hover{
	box-shadow:0px 0px 10px rgba(131,100,226,0.9)
}

.el-backtop{
	background: rgb(131, 100, 226) !important;
	color: #fff !important;
}
</style>
