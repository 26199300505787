import Vue from "vue";
import VueRouter from "vue-router";

import IndexView from "../views/IndexView.vue";
import CollectionView from "../views/CollectionView.vue";
import CollectionItemView from "../views/CollectionItemView.vue";
import RarityView from "../views/RarityView.vue";
import TournamentView from "../views/TournamentView.vue";
import RankingView from "../views/RankingView.vue";
import FaqView from "../views/FaqView.vue";
import test from "../views/test.vue";
import store from "../store/index.js";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    name: "index",
    component: IndexView,
  },
  {
    path: "/collection",
    name: "collection",
    component: CollectionView,
  },
  {
    path: "/item",
    name: "item",
    component: CollectionItemView,
  },
  {
    path: "/rarity",
    name: "rarity",
    component: RarityView,
  },
  {
    path: "/tournament",
    name: "tournament",
    component: TournamentView,
  },
  {
    path: "/ranking",
    name: "ranking",
    component: RankingView,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
  },
  {
    path:"/test",
    name:"test",
    component: test,
  },
  // component: () => import("../views/AboutView.vue"),
];

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  base: '/',
  routes,
});


// 路由守卫防止非法进入collection页面
router.beforeEach((to,from,next)=>{
  if(to.path == '/collection' && store.state.token == ""){
    next('/');
  }else{
    next();
  }
})

export default router;
