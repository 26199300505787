<template>
	<div class="introduce-warp">
		<div class="introduce-box">
			<div class="left wow animate__animated  animate__fadeInUp">
				<img src="../../assets/images/our_game.png" />
			</div>
			<div class="right wow animate__animated  animate__fadeInUp">
				<div class="title">Welcome to Our Game</div>
				<div class="logo-warp">
					<div class="logo">
						<img src="../../assets/images/logo.png"/>
					</div>
				</div>
				<div class="content">
					Build a mighty army and lead your team to victory using the unique skills of your heroes and units. Make use of the Five Elements Synergies to boost your team's stats and outwit your enemies in a single-player, skirmish, and multiplayer PvP modes.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		
	}
</script>

<style scoped="scoped">
	/* introduce容器 */
	.introduce-warp {
		background: url("../../assets/images/collection_background.png") no-repeat center center / cover;
		width: 100%;
		display: flex;
		justify-content: center;
	}
	
	.introduce-box{
		max-width: 1296px;
		width: 100%;
		padding: 90px 40px 90px 40px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	
	.left,.right{width: 49%;}
	
	img{width: 100%;}
	
	.right{
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: center;
	}
	.right>div{
		width: 100%;
	}
	.title{
		font-size: 30px;
		margin-bottom: 20px;
		color: #fff;
		font-weight: 800;
		text-align: left;
	}
	
	.logo{
		width: 300px;
	}
	
	.content{
		text-align: left;
		color: rgb(162, 162, 162);
		font-size: 16px;
	}
	
	@media screen and (max-width: 860px) {
		.left,.right{width: 100%;}
		.right{
			padding-top: 30px;
		}
		.right>div{
			display: flex;
			justify-content: center;
			margin-top: 10px;
		}
	}
</style>
