<template>
	<transition name='floating' enter-active-class="animate__fadeIn" leave-active-class="animate__fadeOut">
		<div class="orderList animate__animated" v-show="orderNum!=0">
			<div>
				<transition name='text' enter-active-class="animate__fadeIn" leave-active-class="animate__fadeOut">
					<div class="text"><span class="animate__animated">{{orderNum}}</span></div>
				</transition>
				<transition name='loading' enter-active-class="animate__fadeIn" leave-active-class="animate__fadeOut">
					<div class="loading animate__animated" v-show="orderNum!=0">
						<font-awesome-icon icon="fa-solid fa-hourglass-start" />
					</div>
				</transition>
			</div>
		</div>
	</transition>
</template>

<script>
	import { mapState,mapMutations } from 'vuex';
	import {getOrderState,onConnect,getCachedProvider,getCurrentAccount} from '@/tools/ethers/newIndex.js';
	import {getList,setLocalStorage} from "@/tools/ethers/orderList/getOrderListToStorage.js";
	import message from '@/tools/message/index.js';

	export default{
		data(){
			return{
				list:[],
				lock:false,
				isShow:[],
				state:true,
			}
		},
		mounted() {
		},
		methods:{
			async getOrserState(timeNum=1000){
				// console.log(1)
				//清楚一下计时器
				clearTimeout(this.timer);

				//创建计时器每秒跑一次
				this.timer = setTimeout(()=>{
					if(0 != this.list.length){

						//如果上锁本次就不用执行

						getOrderState(this.list[0],time=>{
							// console.log("now",time)
						},async (res,time)=>{

							// console.log("callback",time)
							// console.log("res",res)
							if(res){
								if(this.isShow.indexOf(time) != -1){
									this.getOrserState(1000);
									return;
								}
								//判读交易结果
								if(res.status == 1){
									message("Minted!",true)
								}else if(res.status == 0){
									message("Failure!",false)
								}
								this.isShow.push(time);
								this.list.shift(); //出队
								await setLocalStorage(this.list,this.token) //存入本地存储

								this.getOrserState(1000)
								return;
							}else{
								this.getOrserState(1000)
								return;
							}

						});
					}else{
						this.getOrserState(2000)
						return;
					}

				},timeNum)
			},
			...mapMutations(["setOrderList"])
		},
		computed:{
			orderNum(){
				return this.list.length;
			},
			...mapState(['orderList','web3Modal','provider','token'])
		},
		watch:{
			orderList(val){
				// console.log("orderList",val)
				if(val){
					this.list.push(val[val.length - 1]); //入队
					setLocalStorage(this.list,this.token) //存入本地存储
				}
			},
			token:{
				handler(val){
					if(val){
						getList(this.token).then(res=>{
							this.list = res;
							this.getOrserState();
						})
					}
				},
				deep:true
			}
		},
		beforeDestroy(){
			// 清除计时器
			clearInterval(this.timer)
		}
	}
</script>

<style scoped="scoped">
	.orderList{
		position: fixed;
		bottom: 50px;
		right: 50px;
		width: 60px;
		height: 60px;
		border-radius: 50px;
		box-sizing: border-box;
		background-color: rgb(131, 100, 226);
		border: 2px solid rgb(164, 145, 226);
		color: #fff;
		font-size: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 800;
		opacity: 1;
		flex-wrap: wrap;
		z-index: 10;
	}
	.loading{
		width: 60px;
		height: 60px;
		font-size: 20px;
		font-weight: 400;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		animation: loading 2s infinite;
		position: absolute;
		left: 0px;
		top: 0px;
	}
	.text{
		min-width: 24px;
		padding: 5px;
		transform: translate(20px,-20px);
		background-color: #ff5e61;
		height: 24px;
		display: flex;
		font-size: 16px;
		align-items: center;
		justify-content: center;
		right: -6px;
		top: -3px;
		border-radius: 20px;
		transition: all 0.8s;
		box-sizing: border-box;
	}

	@media screen and (max-width: 600px) {
		.orderList{
			right: 10px;
		}
	}

	@keyframes loading{
		from{transform: rotate(0deg);}
		to{transform: rotate(360deg);}
	}
</style>
