<template>
	<transition name='popup' enter-active-class="animate__fadeIn" leave-active-class="animate__fadeOut" >
		<div class="popup animate__animated" v-show="state">
			<transition name='popup' enter-active-class="animate__fadeInDown" leave-active-class="animate__fadeOutUp" >
				<div class="popup-box animate__animated" v-show="state">
					<div class="popup-title">
						<div class="title">{{title}}</div>
						<div class="close" @click="hidden()"><font-awesome-icon icon="fa-solid fa-xmark" /></div>
					</div>
					<div class="popup-content">
						<slot></slot>
					</div>
					<div class="popup-ctrl">
						<button @click="hidden()">Close</button>
						<button v-if="confirm != false" @click="callbackFunction()" :class="{waiting:loading}">
							<span v-show="!loading">{{confirm}}</span>
							<span v-show="loading"><span>Waiting for Transaction</span><i style="margin-left: 10px;" class="el-icon-loading"></i></span>
						</button>
					</div>
				</div>
			</transition>
		</div>
	</transition>
</template>

<script>
	export default{
		props:{
			title:{
				default:'Mint Hero',	//标题
				type:String
			},
			confirm:{
				default:'Confirm',		//确认按钮
			},
			loading:{default:false},
			callback:{}
		},
		data(){
			return{
				state:false
			}
		},
		methods:{
			// 关闭效果
			hidden(){
				this.state = false;
				this.$emit("close",false);
			},
			
			// 打开
			open(){
				this.state = true;
			},
			
			//确认回调
			callbackFunction(){
				if(!this.loading){
					this.callback();
				}
			}
		}
	}
</script>

<style>
	.popup{
		background: rgba(0,0,0,0.4);
		position: fixed;
		width: 100vw;
		height: 100%;
		z-index: 9999;
		/* display: flex; */
		justify-content: center;
		padding: 0px 10px;
		box-sizing: border-box;
	}
	
	.popup-box{
		display: inline-block;
		margin: 28px 0px;
		border-radius: 5px;
		max-width: 500px;
		width: 100%;
		background-color: #272b30;
		border: 1px solid rgba(0,0,0,.2);
	}
	
	.popup-title{
		padding: 16px;
		box-sizing: border-box;
		font-size: 16px;
		font-weight: 600;
		color: #fff;
		display: flex;
		justify-content: space-between;
		text-align: left;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
	
	.close{
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 24px;
		cursor: pointer;
	}
	
	.popup-content{
		width: 468px;
		padding:16px;
		color: rgb(162, 162, 162);
		text-align: left;
		line-height: 26px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
	
	.popup-content>div{
		width: 100%;
	}
	
	.popup-ctrl{
		height: 72px;
		width: 100%;
		padding: 12px;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.popup-ctrl>button{
		height: 36px;
		border-radius: 5px;
		padding: 6px 12px;
		color: #fff;
	}
	.popup-ctrl>button:last-child{
		margin-left: 10px;
		background-color: rgb(64, 63, 131);
		transition: all 0.6s;
	}
	.popup-ctrl>button:first-child{
		background-color: rgb(108, 117, 125)
	}
	
	.waiting{
		background-color: rgb(106, 105, 131) !important;
	}
	
	.popup.animate__animated{
		--animate-duration: 0.3s;
	}
	.popup-box.animate__animated{
		--animate-duration: 1s;
	}
</style>
